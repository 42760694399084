import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';
import img from "./ninovium.png";

function Payment() {
  const navigate = useNavigate();  // useNavigate instead of useHistory
  const location = useLocation();
  const { token, sessionId } = queryString.parse(location.search);
  const storedToken = localStorage.getItem('token');
  const [activeTab, setActiveTab] = useState(() => {
    if (token && sessionId) {
      return 'Confirmation';
    }
    return 'Selection';
  });
  const [userData, setUserData] = useState({ username: '', userid: '', product: '' });
  const [error, setError] = useState('');

  useEffect(() => {
    // Redirect to /register if no token is present
    if (!storedToken) {
      navigate('/register');  // useNavigate for redirection
    }
  }, [storedToken, navigate]);

  useEffect(() => {
    if (activeTab === 'Confirmation' && token && sessionId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://z.ninovium.dev/v1/auth/info?token=${token}&sessionId=${sessionId}`);
          setUserData({
            username: response.data.username,
            userid: response.data.userid,
            product: response.data.product
          });
        } catch (err) {
          setError(err.response ? err.response.data.error : 'Failed to fetch data');
        }
      };
      fetchData();
    }
  }, [activeTab, token, sessionId]);

  return (
    <div>
      <div>
        {[ 'Selection', 'Payment', 'Confirmation'].map(tab => (
          <div key={tab} onClick={() => setActiveTab(tab)}>
            {tab}
          </div>
        ))}
      </div>
      <div>
        {activeTab === 'Selection' && (
          <div>
            <form action={`https://z.ninovium.dev/create-checkout-session?token=${storedToken}`} method="POST">
              <input type="hidden" name="lookup_key" value="Ninovium_Lite-32076e3" />
              <button type="submit">
                <img src={img} alt="Ninovium" />
                <div>Ninovium</div>
                <div>$15.00 / Month</div>
              </button>
            </form>
          </div>
        )}
        {activeTab === 'Payment' && (
          <div>Payment Content</div>
        )}
        {activeTab === 'Confirmation' && token && sessionId && (
          <div>
            <div>
              <img src={img} alt="Ninovium" />
              <div>Ninovium</div>
              <div>$15.00 / Month</div>
            </div>
            <div>
              <p>Welcome {userData.username},</p>
              <p>We have successfully processed your payment...</p>
              {error && <p>Error: {error}</p>}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Payment;
