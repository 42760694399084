import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { marked } from 'marked';
import Prism from 'prismjs';
import 'prismjs/themes/prism-solarizedlight.css'; // Import the theme at the top
import 'prismjs/components/prism-lua';

function Documentation() {
  let navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [content, setContent] = useState('');
  const [parsedContent, setParsedContent] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await fetch('https://z.ninovium.dev/docs.md');
        const text = await response.text();
        const parsed = parseMarkdown(text);
        setParsedContent(parsed);
      } catch (error) {
        console.error('Failed to fetch markdown:', error);
      }
    };

    fetchMarkdown();
  }, []);

  useEffect(() => {
    Prism.highlightAll(); // Ensure this runs after the component has rendered
  }, [parsedContent]);

  marked.setOptions({
    highlight: function (code, lang) {
      if (Prism.languages[lang]) {
        return Prism.highlight(code, Prism.languages[lang], lang);
      } else {
        return Prism.highlight(code, Prism.languages['lua'], 'lua');
      }
    }
  });

  const parseMarkdown = (markdown) => {
    const tokens = marked.lexer(markdown);
    let sections = [];
    let currentSection = null;
    let currentSubsection = null;

    tokens.forEach(token => {
      if (token.type === 'heading') {
        if (token.depth === 1) {
          if (currentSection) sections.push(currentSection);
          currentSection = { title: token.text, subsections: [] };
          currentSubsection = null;
        } else if (token.depth === 2 && currentSection) {
          if (currentSubsection) {
            currentSection.subsections.push(currentSubsection);
          }
          currentSubsection = { title: token.text, content: [] };
        }
      } else if (currentSubsection) {
        currentSubsection.content.push(token);
      }
    });

    if (currentSubsection) {
      currentSection.subsections.push(currentSubsection);
    }

    if (currentSection) {
      sections.push(currentSection);
    }

    return sections;
  };

  const renderContent = () => {
    return parsedContent.map(section => (
      <div key={section.title}>
        <h1>{section.title}</h1>
        {section.subsections.map(subsection => (
          <div key={subsection.title}>
            <h2 id={subsection.title.replace(/\s+/g, '-').toLowerCase()}>{subsection.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: marked.parser(subsection.content) }} />
          </div>
        ))}
      </div>
    ));
  };

  const appStyle = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    height: '100vh',
    width: '100vw',
    left: '0px',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  };

  const hideScrollbar = `
    ::-webkit-scrollbar {
      display: none;
    }
  `;

  const headerStyle = {
    background: 'rgba(0, 0, 0, 0)',
    backdropFilter: 'blur(40px)',
    WebkitBackdropFilter: 'blur(40px)',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 20px',
    zIndex: 1001,
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    marginTop: '20px',
    borderRadius: '50px',
    border: '1px solid #4f47a7',
    boxShadow: '0 0 15px 5px #4f47a7'
  };

  const linkStyle = {
    color: '#f1f1f1',
    textDecoration: 'none',
    margin: '0 10px',
    fontFamily: 'Baloo 2',
    fontSize: '20px',
    cursor: 'pointer',
    transition: 'text-shadow 0.3s ease-in-out',
    textShadow: '0 0 0 rgba(0, 0, 0, 0)'
  };
  
  const linkHoverStyle = {
    textShadow: '0 0 20px #fff'
  };

  const backgroundStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: `#0f172a`,
    zIndex: -1,
  };

  const svgContainerStyle = {
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  };

  const glowBottomStyle = {
    width: '70%',
    height: '65%',
    objectFit: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  };

  const contentContainerStyle = {
    position: 'absolute',
    top: '120px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '80%',
    height: 'calc(100% - 200px)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  };

  const sidebarStyle = {
    width: '250px',
    borderRadius: '40px 0 0 40px',
    marginRight: '20px',
    padding: '20px',
    overflowY: 'auto',
  };

  const sidebarItemStyle = {
    color: '#f1f1f1',
    marginBottom: '10px',
    cursor: 'pointer',
    transition: 'color 0.3s ease-in-out',
    textDecoration: 'none',
    display: 'block',  // Ensure items stack vertically
    paddingLeft: '10px', // Indentation for main titles
  };

  const sidebarSubItemStyle = {
    ...sidebarItemStyle,
    paddingLeft: '20px', // Further indentation for subtitles
    marginBottom: '5px',
  };

  const sidebarItemHoverStyle = {
    color: '#a78bfa',
  };

  const contentStyle = {
    flexGrow: 1,
    backdropFilter: 'blur(20px)',
    WebkitBackdropFilter: 'blur(20px)',
    borderRadius: '40px',
    padding: '0px 40px 0px 40px',
    color: '#f1f1f1',
    fontFamily: 'Baloo 2',
    overflowY: 'auto',
    zIndex: 2,
  };

  return (
    <div style={appStyle}>
      <style>{hideScrollbar}</style>
        <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={headerStyle}>
            <a 
                onMouseEnter={e => e.target.style.textShadow = linkHoverStyle.textShadow}
                onMouseLeave={e => e.target.style.textShadow = linkStyle.textShadow}
                onClick={(e) => { e.preventDefault(); navigate('/'); }} 
                style={linkStyle}
            >
                Home
            </a>
            {!isMobile && (
                <a 
                onMouseEnter={e => e.target.style.textShadow = linkHoverStyle.textShadow}
                onMouseLeave={e => e.target.style.textShadow = linkStyle.textShadow}
                href="/documentation" 
                style={linkStyle}
                >
                Documentation
                </a>
            )}
            {!isMobile && (
                <a 
                onMouseEnter={e => e.target.style.textShadow = linkHoverStyle.textShadow}
                onMouseLeave={e => e.target.style.textShadow = linkStyle.textShadow}
                href="/terms-of-service" 
                style={linkStyle}
                >
                Terms of Service
                </a>
            )}
            <a 
                onMouseEnter={e => e.target.style.textShadow = linkHoverStyle.textShadow}
                onMouseLeave={e => e.target.style.textShadow = linkStyle.textShadow}
                href="/support" 
                style={linkStyle}
            >
                Support
            </a>
            </div>
            <div style={backgroundStyle}></div>
            <div style={svgContainerStyle}>
            <div style={glowBottomStyle}>
            </div>
            </div>
            <div style={contentContainerStyle}>
              <div style={sidebarStyle}>
                <p style={{ fontSize: '2rem', marginTop: '0rem', color: 'white', fontWeight: '700' }}>Documentation</p>
                {parsedContent.map(section => (
                  <div key={section.title}>
                    <a
                      href={`#${section.title.replace(/\s+/g, '-').toLowerCase()}`}
                      style={sidebarItemStyle}
                      onMouseEnter={e => e.target.style.color = sidebarItemHoverStyle.color}
                      onMouseLeave={e => e.target.style.color = sidebarItemStyle.color}
                    >
                      {section.title}
                    </a>
                    {section.subsections.map(subsection => (
                      <a
                        key={subsection.title}
                        href={`#${subsection.title.replace(/\s+/g, '-').toLowerCase()}`}
                        style={sidebarSubItemStyle}
                        onMouseEnter={e => e.target.style.color = sidebarItemHoverStyle.color}
                        onMouseLeave={e => e.target.style.color = sidebarSubItemStyle.color}
                      >
                        {subsection.title}
                      </a>
                    ))}
                  </div>
                ))}
              </div>
              <div style={contentStyle}>
                {renderContent()}
              </div>
            </div>
        </div>
    </div>
  );
}

export default Documentation;
