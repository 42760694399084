import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import Payment from './payment';
import Meow from './meow';
import Signup from './signup'; // Import the Signup component
import Documentation from './docs';
import Tos from './tos';
import Portal from './portal';
import GetStarted from './getstarted';
import DevelopmentTeam from './devteam'; // Import the DevelopmentTeam component
import Support from './support'; // Import the DevelopmentTeam component

const DevModeWrapper = ({ children }) => {
  const isDevMode = localStorage.getItem('dev') === 'true';
  return isDevMode ? (
    <>{children}</>
  ) : (
    <div style={{ textAlign: 'center', marginTop: '20vh' }}>
      <h1>Work In Progress</h1>
      <p>The site is currently under development. Please check back later.</p>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <DevModeWrapper>
    <BrowserRouter>
      <Routes style={{ overflowX: 'hidden' }}>
        <Route path="/" element={<App />} />
        <Route path="/paydemo" element={<Meow />} />
        <Route path="/purchase" element={<Payment />} />
        <Route path="/register" element={<Signup />} />
        <Route path="/documentation" element={<Documentation />} />
        <Route path="/terms" element={<Tos />} />
        <Route path="/portal" element={<Portal />} />
        <Route path="/support" element={<Support />} />
        <Route path="/get-started" element={<GetStarted />} />
        <Route path="/devteam" element={<DevelopmentTeam />} /> {/* Added the new route */}
      </Routes>
    </BrowserRouter>
  </DevModeWrapper>
);
