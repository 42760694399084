import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import Payment from './payment';
import Meow from './meow';
import Signup from './signup'; // Import the Signup component
import Documentation from './docs';
import Tos from './tos';
import Portal from './portal';

// Wrapper component to check for 'dev' mode in localStorage
const DevModeWrapper = ({ children }) => {
  // Check for 'dev' mode
  const isDevMode = localStorage.getItem('dev') === 'true';

  // Conditionally render children or nothing
  return isDevMode ? <>{children}</> : null;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <DevModeWrapper>
      <BrowserRouter>
        <Routes style={{ overflowX: 'hidden' }}>
          <Route path="/" element={<App />} />
          <Route path="/paydemo" element={<Meow />} />
          <Route path="/purchase" element={<Payment />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/terms-of-service" element={<Tos />} />
          <Route path="/portal" element={<Portal />} />
        </Routes>
      </BrowserRouter>
    </DevModeWrapper>
);
