import React, { useEffect, useState } from 'react';
import { FaCcStripe, FaBitcoin } from "react-icons/fa6";
import { useNavigate, Link } from 'react-router-dom';

function Portal() {
  const navigate = useNavigate();
  const [portalInfo, setPortalInfo] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [amount, setAmount] = useState(0);
  const [adjustedAmount, setAdjustedAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dueThisMonth, setDueThisMonth] = useState(0);
  const [copyKeyLabel, setCopyKeyLabel] = useState("Copy Key");

  const calculateStartDate = (endDate) => {
    const date = new Date(endDate);
    date.setMonth(date.getMonth() - 1);
    return date;
  };

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    if (amount && amount > 0) {
      const adjusted = Math.round(((parseFloat(amount) + 0.30) / 0.971) * 100) / 100;
      setAdjustedAmount(adjusted);
    } else {
      setAdjustedAmount(0);
    }
  }, [amount]);

  const calculateDueThisMonth = (subscriptions) => {
    let total = 0;
    subscriptions.forEach(sub => {
      if (sub.subscribed) {
        total += parseFloat(sub.monthlyCost.replace('$', ''));
      }
    });
    return total;
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetch(`https://z.ninovium.dev/v1/auth/validate?token=${token}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "Token is valid.") {
            return fetch(`https://z.ninovium.dev/v1/auth/portal-info?token=${token}`);
          } else {
            navigate('/register');
          }
        })
        .then((response) => response && response.json())
        .then((data) => {
          if (data && data.message === "Token is valid.") {
            setPortalInfo({
              userid: data.userid,
              username: data.username,
              amount: data.amount,
            });
          } else {
            navigate('/register');
          }
        })
        .catch(() => {
          navigate('/register');
        });

      fetch(`https://z.ninovium.dev/v1/subscriptions?token=${token}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "No subscriptions found.") {
            setSubscriptions([]);
          } else if (data && data.subscriptions) {
            const userSubscriptions = [
              {
                name: 'Ninovium Lua Debugger',
                status: data.subscriptions?.ninovium_ld?.subscribed ? 'Active' : 'Inactive',
                startDate: data.subscriptions?.ninovium_ld?.renewal_date
                  ? formatDate(calculateStartDate(data.subscriptions.ninovium_ld.renewal_date))
                  : 'Not Subscribed',
                endDate: data.subscriptions?.ninovium_ld?.renewal_date
                  ? formatDate(data.subscriptions.ninovium_ld.renewal_date)
                  : 'Not Subscribed',
                monthlyCost: '$8.50',
                subscribed: data.subscriptions?.ninovium_ld?.subscribed || false,
              },
              {
                name: 'Oracle Lua Decompiler',
                status: data.subscriptions?.oracle?.subscribed ? 'Active' : 'Inactive',
                startDate: data.subscriptions?.oracle?.renewal_date
                  ? formatDate(calculateStartDate(data.subscriptions.oracle.renewal_date))
                  : 'Not Subscribed',
                endDate: data.subscriptions?.oracle?.renewal_date
                  ? formatDate(data.subscriptions.oracle.renewal_date)
                  : 'Not Subscribed',
                monthlyCost: '$5.00',
                subscribed: data.subscriptions?.oracle?.subscribed || false,
              }
            ];

            setSubscriptions(userSubscriptions);
            setDueThisMonth(calculateDueThisMonth(userSubscriptions));
          }
        })
        .catch((error) => console.error('Error fetching subscriptions:', error))
        .finally(() => setLoading(false));
    } else {
      navigate('/register');
    }
  }, [navigate]);

  // Function to get the subscription status and decide button color
  const getButtonColor = (service) => {
    const subscription = subscriptions.find(sub => sub.name === service);
    return subscription && subscription.subscribed ? colorScheme.redButton : colorScheme.primaryButton;
  };

  const handleStripePayment = () => {
    const token = localStorage.getItem('token');
    if (!amount || amount <= 0) {
      alert('Please enter a valid amount to deposit.');
      return;
    }

    fetch(`https://z.ninovium.dev/create-checkout-session?token=${token}&amount=${amount}`, {
      method: 'POST',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.url) {
          window.location.href = data.url;
        } else {
          alert('Error creating checkout session. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('There was an issue processing your payment. Please try again.');
      });
  };

  const handleSubscribeOrUnsubscribe = (service, subscribed) => {
    const token = localStorage.getItem('token');
    const endpoint = subscribed ? 'unsubscribe' : 'purchase';

    if (subscribed) {
      const confirmation = window.prompt('Type "unsubscribe" to confirm you want to unsubscribe:');
      if (confirmation !== 'unsubscribe') {
        alert('Unsubscription not confirmed. Please type "unsubscribe" to proceed.');
        return;
      }
    }

    fetch(`https://z.ninovium.dev/v1/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token,
        service, // Pass the service being subscribed/unsubscribed ('oracle' or 'ninovium_ld')
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          alert(data.message);

          // If subscribing and service is 'ninovium_ld', generate or retrieve the key
          if (!subscribed && service === 'ninovium_ld') {
            handleNinoviumKey(token);
          }

          // Reload to update subscriptions
          window.location.reload();
        } else {
          alert(data.error || `Error processing ${subscribed ? 'unsubscription' : 'subscription'}.`);
        }
      })
      .catch((error) => {
        console.error(`Error processing ${subscribed ? 'unsubscription' : 'subscription'}:`, error);
        alert(`There was an issue with your ${subscribed ? 'unsubscription' : 'subscription'}. Please try again.`);
      });
  };

  const handleNinoviumKey = (token) => {
    // First, try to retrieve the existing key
    fetch(`https://z.ninovium.dev/v1/get-key`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token, product: 'ninovium_ld' }), // Specify the product
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.key) {
          // Check if the clipboard API is available
          if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(data.key)
              .then(() => {
                setCopyKeyLabel('Copied Key'); // Change button label to "Copied Key"
                setTimeout(() => {
                  setCopyKeyLabel('Copy Key'); // Revert back to "Copy Key" after 2 seconds
                }, 2000);
              })
              .catch((error) => {
                console.error('Failed to copy key:', error);
                alert('Failed to copy the key. Please try again.');
              });
          } else {
            // Fallback method for browsers that don't support the clipboard API
            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = data.key;
            document.body.appendChild(tempTextArea);
            tempTextArea.select();
            try {
              document.execCommand('copy');
              setCopyKeyLabel('Copied Key');
              setTimeout(() => {
                setCopyKeyLabel('Copy Key');
              }, 2000);
            } catch (error) {
              console.error('Fallback: Failed to copy key:', error);
              alert('Fallback: Failed to copy the key. Please try again.');
            } finally {
              document.body.removeChild(tempTextArea);
            }
          }
        } else {
          // If no key exists, generate a new one
          fetch(`https://z.ninovium.dev/v1/generate-key`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token, product: 'ninovium_ld' }), // No HWID for first-time key generation
          })
            .then((response) => response.json())
            .then((data) => {
              if (data && data.key) {
                if (navigator.clipboard && navigator.clipboard.writeText) {
                  navigator.clipboard.writeText(data.key)
                    .then(() => {
                      setCopyKeyLabel('Copied Key'); // Change button label to "Copied Key"
                      setTimeout(() => {
                        setCopyKeyLabel('Copy Key'); // Revert back to "Copy Key" after 2 seconds
                      }, 2000);
                    })
                    .catch((error) => {
                      console.error('Failed to copy key:', error);
                      alert('Failed to copy the key. Please try again.');
                    });
                } else {
                  // Fallback for browsers that don't support clipboard API
                  const tempTextArea = document.createElement('textarea');
                  tempTextArea.value = data.key;
                  document.body.appendChild(tempTextArea);
                  tempTextArea.select();
                  try {
                    document.execCommand('copy');
                    setCopyKeyLabel('Copied Key');
                    setTimeout(() => {
                      setCopyKeyLabel('Copy Key');
                    }, 2000);
                  } catch (error) {
                    console.error('Fallback: Failed to copy key:', error);
                    alert('Fallback: Failed to copy the key. Please try again.');
                  } finally {
                    document.body.removeChild(tempTextArea);
                  }
                }
              } else {
                alert('Error generating key. Please try again.');
              }
            })
            .catch((error) => {
              console.error('Error generating key:', error);
              alert('Error generating key. Please try again.');
            });
        }
      })
      .catch((error) => {
        console.error('Error retrieving key:', error);
        alert('Error retrieving key. Please try again.');
      });
  };

  const colorScheme = {
    primaryButton: '#7e56ef', // Matching color from the first component for buttons
    hoverButton: '#4f47a7', // Hover color from the first component
    borderColor: '#e2e8f0', // Light border for cards
    textColor: '#4f47a7', // Matching text color from first component
    lightText: '#888888',
    sectionBg: '#ffffff',
    redButton: '#f87171', // For unsubscribe button
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundImage: 'url("https://z.ninovium.dev/Untitled.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const headerStyle = {
    background: 'rgba(0, 0, 0, 0.05)',
    backdropFilter: 'blur(40px)',
    WebkitBackdropFilter: 'blur(40px)',
    borderRadius: '0 0 20px 20px',
    borderBottom: '1px solid rgba(79, 71, 167, 0.3)',
    boxShadow: '0 2px 10px rgba(79, 71, 167, 0.1)',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 50px',
    zIndex: 1001,
    position: 'absolute',
    top: 0,
    width: 'calc(100vw - 160px)',
    margin: '0 30px 0 30px',
    fontSize: '18px',
    color: '#4f47a7',
    fontFamily: 'Baloo 2',
  };

  const linkStyle = {
    color: '#4f47a7',
    textDecoration: 'none',
    margin: '0 20px',
    fontFamily: 'Baloo 2',
    fontSize: '18px',
    cursor: 'pointer',
    transition: 'color 0.3s ease-in-out',
  };

  const linkHoverStyle = {
    color: '#6a5ecf',
  };

  const navLinksStyle = {
    display: 'flex',
    gap: '20px',
    fontSize: '16px',
  };

  const navLinkStyle = {
    textDecoration: 'none',
    color: colorScheme.textColor,
    fontWeight: 'bold',
  };

  const mainContentStyle = {
    flex: 1,
    padding: '30px',
    margin: '80px 30px 0 30px',
    borderRadius: '20px 20px 0 0',
    background: '#f1f1f1',
    backdropFilter: 'blur(40px)',
    WebkitBackdropFilter: 'blur(40px)',
    borderBottom: '1px solid rgba(79, 71, 167, 0.3)',
    boxShadow: '0 2px 10px rgba(79, 71, 167, 0.1)',
    borderTop: `1px solid rgba(79, 71, 167, 0.3)`,
  };

  const cardStyle = {
    padding: '20px',
    marginBottom: '20px',
    flex: '3',
  };

  const productCatalogStyle = {
    display: 'flex',
    gap: '20px',
    padding: '20px',
    borderTop: `2px solid rgba(79, 71, 167, 0.3)`,
    borderBottom: `2px solid rgba(79, 71, 167, 0.3)`,
    justifyContent: 'space-between',
  };

  const rightSidebarStyle = {
    padding: '30px 30px 30px 30px',
    color: colorScheme.textColor,
    flex: '1 1 300px',
    display: 'flex',
    flexDirection: 'column',
    borderRight: `2px solid rgba(79, 71, 167, 0.3)`,
  };

  const flexContainer = {
    display: 'flex',
    gap: '20px',
    alignItems: 'stretch',
  };

  const depositButtonStyle = {
    padding: '10px',
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    fontWeight: '700',
  };

  const stripeButtonStyle = {
    ...depositButtonStyle,
    backgroundColor: colorScheme.primaryButton,
  };

  const bitcoinButtonStyle = {
    ...depositButtonStyle,
    backgroundColor: '#F7931A', // Bitcoin's brand color
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '10px',
  };

  const tableHeaderStyle = {
    textAlign: 'left',
    fontWeight: 'bold',
    padding: '10px',
    borderBottom: `2px solid rgba(79, 71, 167, 0.3)`,
    color: colorScheme.textColor,
  };

  const tableCellStyle = {
    padding: '10px',
    borderBottom: `1px solid rgba(79, 71, 167, 0.3)`,
  };

  const ninoviumProductStyle = {
    flex: '1 1 50%',
    padding: '30px',
    borderRadius: '15px',
    textAlign: 'left', // Align text to the left
    background: 'linear-gradient(135deg, #e9f0ff, #f2e8fc)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    cursor: 'pointer',
    border: `1px solid rgba(79, 71, 167, 0.3)`,
  };

  const oracleProductStyle = {
    flex: '1 1 50%',
    padding: '30px',
    borderRadius: '15px',
    textAlign: 'left', // Align text to the left
    background: 'linear-gradient(135deg, #fff1ea, #ffeaea)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    cursor: 'pointer',
    border: `1px solid rgba(79, 71, 167, 0.3)`,
  };

  const productCardHoverEffect = {
    ':hover': {
      transform: 'translateY(-10px)',
      boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)',
    },
  };

  const ninoviumProductHoverStyle = {
    ...ninoviumProductStyle,
    ...productCardHoverEffect,
  };

  const oracleProductHoverStyle = {
    ...oracleProductStyle,
    ...productCardHoverEffect,
  };

  const purchaseButtonStyle = {
    backgroundColor: colorScheme.primaryButton,
    padding: '10px 20px',
    borderRadius: '8px',
    color: '#fff',
    border: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  };

  const handleDownload = () => {
    window.location.href = 'https://z.ninovium.dev/release/ninovium-installer.exe';
  };

  const getButtonText = (service) => {
    const subscription = subscriptions.find(sub => sub.name === service);
    return subscription && subscription.subscribed ? 'Unsubscribe' : 'Subscribe';
  };

  const getButtonTex2t = (service) => {
    const subscription = subscriptions.find(sub => sub.name === service);
    return subscription && subscription.subscribed; // This returns a boolean (true/false)
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ ...containerStyle, color: '#4a4a4a' }}>
      <div style={{ ...headerStyle, color: '#333333' }}>
        <img src="https://z.ninovium.dev/logo17.png" alt="Ninovium Logo" style={{ height: '40px' }} />
        <div style={navLinksStyle}>
          <Link to="/" style={{ ...linkStyle, color: '#4f47a7'}}>Home</Link>
          <Link to="/docs" style={linkStyle}>Documentation</Link>
          <Link to="/support" style={linkStyle}>Support</Link>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '30px' }}>
          <a
            href={`https://z.ninovium.dev/link/discord?token=${localStorage.getItem('token')}`}
            style={{
              marginRight: '20px',
              padding: '4px 15px',
              backgroundColor: '#5865F2', // Discord brand color
              color: '#fff',
              borderRadius: '5px',
              textDecoration: 'none',
              fontWeight: 'bold',
            }}
          >
            Link to Discord
          </a>
          <strong style={{ color: '#5865f2' }}>{portalInfo ? portalInfo.username : 'Loading...'}</strong>
        </div>
      </div>


      <div style={mainContentStyle}>
        <div style={flexContainer}>
          <div style={{ ...rightSidebarStyle, maxWidth: '300px' }}>
            {portalInfo ? (
              <>
                <p style={{ fontSize: '18px', fontWeight: '700', color: '#4f47a7' }}>Balance (USD)</p>
                <p style={{ fontSize: '16px', marginTop: '-15px', marginBottom: '-10px', color: '#4f47a7' }}>
                  ${(portalInfo.amount ? Number(portalInfo.amount) : 0).toLocaleString()}
                </p>
                <p style={{ fontSize: '18px', fontWeight: '700', color: '#4f47a7' }}>Paid this month</p>
                <p style={{ fontSize: '16px', marginTop: '-15px', color: '#4f47a7' }}>${dueThisMonth.toFixed(2)}</p>
              </>
            ) : (
              <p>Loading account information...</p>
            )}
            <h3 style={{ color: '#4f47a7', marginTop: '-5px' }}>Deposit</h3>
            <input
              type="number"
              placeholder="Enter amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)} // Update the amount value
              style={{
                width: 'calc(100% - 20px)',
                padding: '10px',
                marginBottom: '-10px',
                borderRadius: '8px',
                outline: 'none',
                border: `1px solid rgba(79, 71, 167, 0.3)`,
              }}
            />
            <p style={{ fontSize: '14px', color: '#888888' }}>
              After Stripe fees: <strong>${adjustedAmount}</strong>
            </p>
            <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
              <button
                style={stripeButtonStyle}
                onClick={handleStripePayment}
                disabled={adjustedAmount < 10} // Disable if adjusted amount is less than $10
              >
                <FaCcStripe size={30} /> Stripe
              </button>
              <button
                style={bitcoinButtonStyle}
                disabled={adjustedAmount < 10} // Disable if adjusted amount is less than $10
              >
                <FaBitcoin size={30} /> BtcPay
              </button>
            </div>
          </div>

          <div style={{ ...cardStyle }}>
            <h2 style={{ color: '#4f47a7' }}>Active Subscriptions</h2>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={tableHeaderStyle}>Name</th>
                  <th style={tableHeaderStyle}>Start Date</th>
                  <th style={tableHeaderStyle}>Renewal Date</th>
                  <th style={tableHeaderStyle}>Monthly Cost</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.map((sub, index) => (
                  <tr key={index}>
                    <td style={tableCellStyle}>{sub.name}</td>
                    <td style={tableCellStyle}>{sub.startDate}</td>
                    <td style={tableCellStyle}>{sub.endDate}</td>
                    <td style={tableCellStyle}>{sub.monthlyCost}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div style={productCatalogStyle}>
          {/* Ninovium Product */}
          <div style={ninoviumProductHoverStyle}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src="https://ninovium.dev/ninovium.png"
                alt="Ninovium Icon"
                style={{ width: '60px', height: '60px', marginRight: '20px' }}
              />
              <div style={{ flexGrow: 1 }}>
                <h4 style={{ fontSize: '22px', fontWeight: 'bold', margin: '0' }}>Ninovium Lua Debugger</h4>
                <p style={{ fontSize: '16px', color: '#555', margin: '0', marginTop: '5px' }}>$8.50/month</p>
              </div>

              {/* Display Copy Key, Download, and Unsubscribe buttons if subscribed */}
              {getButtonTex2t('Ninovium Lua Debugger') && (
                <>
                  <button
                    style={{
                      ...purchaseButtonStyle,
                      backgroundColor: '#80c382', // Green color for Copy Key button
                      marginRight: '10px', // Add some spacing between the buttons
                    }}
                    onClick={() => handleNinoviumKey(localStorage.getItem('token'))}
                  >
                    {copyKeyLabel}
                  </button>

                  <button
                    style={{
                      ...purchaseButtonStyle,
                      backgroundColor: '#80c382', // Green color for Download button
                      marginRight: '10px',
                    }}
                    onClick={handleDownload}
                  >
                    Download
                  </button>
                </>
              )}

              <button
                style={{
                  ...purchaseButtonStyle,
                  backgroundColor: getButtonColor('Ninovium Lua Debugger'),
                }}
                onClick={() => handleSubscribeOrUnsubscribe('ninovium_ld', getButtonTex2t('Ninovium Lua Debugger'))}
              >
                {getButtonText('Ninovium Lua Debugger')}
              </button>
            </div>
          </div>
          {/* Oracle Product */}
          <div style={oracleProductHoverStyle}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src="https://z.ninovium.dev/oracle.png"
                alt="Oracle Icon"
                style={{ width: '60px', height: '60px', marginRight: '20px' }}
              />
              <div style={{ flexGrow: 1 }}>
                <h4 style={{ fontSize: '22px', fontWeight: 'bold', margin: '0' }}>Oracle Lua Decompiler</h4>
                <p style={{ fontSize: '16px', color: '#555', margin: '0', marginTop: '5px' }}>$5.00/month</p>
              </div>
              <button
                style={{
                  ...purchaseButtonStyle,
                  backgroundColor: getButtonColor('Oracle Lua Decompiler'),
                }}
                onClick={() => handleSubscribeOrUnsubscribe('oracle', getButtonTex2t('Oracle Lua Decompiler'))}
              >
                {getButtonText('Oracle Lua Decompiler')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portal;
