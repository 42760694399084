import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Argon2, Argon2Mode } from '@sphereon/isomorphic-argon2';

// GradientButton Component (Unchanged)
const GradientButton = ({ children, onClick, style, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);

  const defaultStyle = {
    padding: '15px 30px',
    borderRadius: '30px',
    border: 'none',
    background: 'linear-gradient(90deg, #9c7ef3, #7e56ef)',
    backgroundSize: '200% 200%',
    backgroundPosition: isHovered ? 'right' : 'left',
    color: 'white',
    fontSize: '18px',
    fontFamily: 'Baloo 2',
    cursor: 'pointer',
    boxShadow: '0 2px 10px rgba(79, 71, 167, 0.4)',
    transition: 'background-position 0.5s ease-in-out, transform 0.3s ease-in-out',
    ...style,
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <button
      style={defaultStyle}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    >
      {children}
    </button>
  );
};

function Signup() {
  const navigate = useNavigate();
  const location = useLocation();
  const [darkMode, setDarkMode] = useState(() => JSON.parse(localStorage.getItem('darkMode')) || false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState('Sign Up');
  const [captchaResponse, setCaptchaResponse] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [captchaSvg, setCaptchaSvg] = useState('');
  const [captchaUrl, setCaptchaUrl] = useState('https://z.ninovium.dev/captcha');
  const [isMobile, setIsMobile] = useState(false);
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);
  const [isLogin, setIsLogin] = useState(false); // Toggle between Sign Up and Log In
  const [isResetPassword, setIsResetPassword] = useState(false); // Show Reset Password form
  const [resetToken, setResetToken] = useState('');

  // State variables for reset password button
  const [resetButtonDisabled, setResetButtonDisabled] = useState(false);
  const [resetButtonText, setResetButtonText] = useState('Send Request');
  const resetButtonTimeoutRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check on initial load

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
    fetchCaptcha();

    // Check if URL contains a reset token and email
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const emailParam = queryParams.get('email');

    if (token && emailParam) {
      setResetToken(token);
      setEmail(emailParam); // Extract the email from the URL
      setButtonText('Change Password'); // Set button text for password reset form
    }

    // Check if the reset button should be disabled
    const resetButtonReenableTime = localStorage.getItem('resetButtonReenableTime');
    if (resetButtonReenableTime) {
      const timeLeft = resetButtonReenableTime - Date.now();
      if (timeLeft > 0) {
        setResetButtonDisabled(true);
        setResetButtonText('Password reset link sent to your email.');
        // Set a timeout to re-enable the button after the remaining time
        if (resetButtonTimeoutRef.current) {
          clearTimeout(resetButtonTimeoutRef.current);
        }
        resetButtonTimeoutRef.current = setTimeout(() => {
          setResetButtonDisabled(false);
          setResetButtonText('Send Request');
          localStorage.removeItem('resetButtonReenableTime');
        }, timeLeft);

        // Clean up the timeout if component unmounts
        return () => {
          if (resetButtonTimeoutRef.current) {
            clearTimeout(resetButtonTimeoutRef.current);
          }
        };
      } else {
        // Time has passed, remove the item
        localStorage.removeItem('resetButtonReenableTime');
      }
    }
  }, [darkMode, location.search]);

  const fetchCaptcha = async () => {
    try {
      const response = await axios.get(captchaUrl);
      setCaptchaSvg(`data:image/svg+xml;base64,${btoa(response.data.captchaSvg)}`); // Set the CAPTCHA SVG as a data URL
      setCaptchaToken(response.data.captchaToken); // Save the CAPTCHA token
    } catch (err) {
      console.error('Failed to load CAPTCHA:', err);
    }
  };

  const handleAction = async () => {
    setButtonDisabled(true);
    setButtonText('Processing...');

    try {
      const hashResult = await Argon2.hash(password, email, {
        hashLength: 32,
        memory: 1024,
        parallelism: 1,
        mode: Argon2Mode.Argon2id,
        iterations: 1,
      });

      if (isLogin) {
        // Login flow
        const { data } = await axios.post('https://z.ninovium.dev/login', {
          email,
          passwordhash: hashResult.hex,
          captcha: captchaResponse,
          captchaToken,
        });

        if (data.token) {
          localStorage.setItem('token', data.token);
          navigate('/');
        } else {
          setButtonText('Login failed, please try again.');
          setButtonDisabled(false);
        }
      } else {
        // Signup flow
        const { data } = await axios.post('https://z.ninovium.dev/register', {
          username,
          email,
          passwordhash: hashResult.hex,
          captcha: captchaResponse,
          captchaToken,
        });

        if (data.message.includes('User registered successfully')) {
          setButtonText('Awaiting Email Verification');
          checkEmailVerification(hashResult.hex);
        } else {
          setButtonText(data.message);
          setButtonDisabled(false);
        }
      }
    } catch (error) {
      console.error('Error during action:', error);
      const errorMessage = error.response?.data?.message || `${isLogin ? 'Login' : 'Registration'} failed.`;
      setButtonText(errorMessage);
      setButtonDisabled(false);
    }
  };

  const checkEmailVerification = async (passwordHash) => {
    const interval = setInterval(async () => {
      try {
        const { data } = await axios.post('https://z.ninovium.dev/login', {
          email,
          passwordhash: passwordHash,
          captcha: captchaResponse,
          captchaToken,
        });

        if (data.token) {
          clearInterval(interval);
          localStorage.setItem('token', data.token);
          navigate('/');
        }
      } catch (error) {
        if (error.response?.status === 403) {
          setButtonText('Awaiting Email Verification...');
        } else {
          clearInterval(interval);
          setButtonText('Login failed, please verify your email and try again.');
          setButtonDisabled(false);
        }
      }
    }, 2000);
  };

  const openCaptchaModal = () => {
    setShowCaptchaModal(true);
    fetchCaptcha();
  };

  const closeCaptchaModal = () => {
    setShowCaptchaModal(false);
  };

  const handleCaptchaSubmit = (e) => {
    e.preventDefault();
    setShowCaptchaModal(false);
    handleAction();
  };

  const toggleLoginSignup = () => {
    setIsLogin(!isLogin);
    setIsResetPassword(false);
    setButtonText(!isLogin ? 'Log In' : 'Sign Up');
  };

  const openResetPassword = () => {
    setIsResetPassword(true);
    setButtonText('Send Request');
  };

  const handlePasswordReset = async () => {
    try {
      await axios.post('https://z.ninovium.dev/reset-password-request', { email });
      setResetButtonText('Password reset link sent to your email.');
      setResetButtonDisabled(true);

      // Save the re-enable time in localStorage
      const reenableTime = Date.now() + 600000; // 10 minutes from now
      localStorage.setItem('resetButtonReenableTime', reenableTime);

      // Start a timeout to re-enable the button after 10 minutes
      if (resetButtonTimeoutRef.current) {
        clearTimeout(resetButtonTimeoutRef.current);
      }
      resetButtonTimeoutRef.current = setTimeout(() => {
        setResetButtonDisabled(false);
        setResetButtonText('Send Request');
        localStorage.removeItem('resetButtonReenableTime');
      }, 600000); // 600,000 milliseconds = 10 minutes
    } catch (error) {
      console.error('Error during password reset:', error);
      setResetButtonText('Failed to send password reset link.');
      setResetButtonDisabled(false);
    }
  };

  const handleResetPassword = async () => {
    if (password !== confirmPassword) {
      setButtonText('Passwords do not match.');
      return;
    }

    try {
      // Hash the new password with the email
      const hashResult = await Argon2.hash(password, email, {
        hashLength: 32,
        memory: 1024,
        parallelism: 1,
        mode: Argon2Mode.Argon2id,
        iterations: 1,
      });

      await axios.post('https://z.ninovium.dev/reset-password', {
        token: resetToken,
        newPasswordHash: hashResult.hex,
      });

      setButtonText('Password reset successfully. Please log in.');
      setResetToken('');
    } catch (error) {
      console.error('Error during password reset:', error);
      setButtonText('Failed to reset password.');
    }
  };

  const formStyle = {
    width: '100%',
    maxWidth: '500px',
    background: 'rgba(0, 0, 0, 0.05)',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '20px',
    border: '1px solid rgba(79, 71, 167, 0.3)',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease', // Smooth transition for expand/contract
  };

  const inputStyle = {
    width: 'calc(100% - 20px)',
    padding: '10px',
    borderRadius: '10px',
    border: '1px solid #ccc',
    fontSize: '16px',
    outline: 'none',
    marginBottom: '10px',
  };

  const sliderContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    position: 'relative',
    width: '100%',
    maxWidth: '500px',
    borderRadius: '30px',
  };

  const sliderButtonStyle = {
    flex: 1,
    padding: '10px',
    textAlign: 'center',
    cursor: 'pointer',
    color: '#3f2d7e',
    fontFamily: 'Baloo 2',
    fontSize: '25px',
    zIndex: 2,
  };

  const sliderIndicatorStyle = {
    position: 'absolute',
    bottom: '0px',
    left: isLogin ? '50%' : '0%',
    width: '40px',
    marginLeft: 'calc(25% - 20px)',
    height: '4px',
    backgroundColor: '#3f2d7e',
    transition: 'left 0.3s ease',
    borderRadius: '30px 30px 0 0',
  };

  // Spinner Component for loading states (Unchanged)
  const Spinner = () => (
    <div
      style={{
        border: '4px solid #f3f3f3',
        borderTop: '4px solid #3f2d7e',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        animation: 'spin 1s linear infinite',
        marginRight: '10px',
      }}
    ></div>
  );

  return (
    <div
      style={{
        position: 'relative',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', // Center vertically
        alignItems: 'center', // Center horizontally
        backgroundImage: `url('https://z.ninovium.dev/bg-test.jpg')`,
        backgroundSize: 'cover',
        padding: '20px', // Add padding for smaller screens
        boxSizing: 'border-box',
        overflow: 'hidden'
      }}
    >
      {/* Inline CSS for Animations */}
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }

          @keyframes slide-up {
            from {
              transform: translateY(100%);
              opacity: 0;
            }
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }

          .slide-up {
            animation: slide-up 0.5s ease-out forwards;
          }
        `}
      </style>

      {/* Password Reset Form */}
      {resetToken ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            width: '100%',
            maxWidth: '500px',
          }}
        >
          <div style={formStyle} className="slide-up">
            <input
              style={inputStyle}
              type="password"
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <input
              style={inputStyle}
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <GradientButton onClick={handleResetPassword} disabled={buttonDisabled} style={{ width: '100%', marginTop: '10px' }}>
              {buttonDisabled ? (
                <>
                  <Spinner /> Changing...
                </>
              ) : (
                'Change Password'
              )}
            </GradientButton>
          </div>
        </div>
      ) : isResetPassword ? (
        // Reset Password Request Form
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            width: '100%',
            maxWidth: '500px',
          }}
        >
          <div style={formStyle} className="slide-up">
            <h2 style={{ color: '#3f2d7e', fontWeight: '500', marginBottom: '20px' }}>Reset Password</h2>
            <input
              style={inputStyle}
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <GradientButton
              onClick={handlePasswordReset}
              disabled={resetButtonDisabled}
              style={{
                width: '100%',
                marginTop: '10px',
                background: resetButtonDisabled ? '#ccc' : undefined,
                cursor: resetButtonDisabled ? 'not-allowed' : 'pointer',
                boxShadow: resetButtonDisabled ? 'none' : undefined,
              }}
            >
              {resetButtonDisabled ? 'Password reset link sent to your email.' : 'Send Request'}
            </GradientButton>
          </div>
        </div>
      ) : (
        // Signup or Login Form
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            width: '100%',
            maxWidth: '500px',
          }}
        >
          <div style={formStyle} className="slide-up">
            <div style={sliderContainerStyle}>
              <div style={sliderButtonStyle} onClick={toggleLoginSignup}>
                Sign Up
              </div>
              <div style={sliderButtonStyle} onClick={toggleLoginSignup}>
                Log In
              </div>
              <div style={sliderIndicatorStyle}></div>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                openCaptchaModal();
              }}
              style={{ width: '100%' }}
            >
              {!isLogin && (
                <input
                  style={inputStyle}
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              )}
              <input
                style={inputStyle}
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                style={inputStyle}
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <GradientButton type="submit" disabled={buttonDisabled} style={{ width: '100%', marginTop: '10px' }}>
                {buttonDisabled ? (
                  <>
                    <Spinner /> {buttonText}
                  </>
                ) : (
                  buttonText
                )}
              </GradientButton>
            </form>
            <div
              style={{
                marginTop: '10px',
                cursor: 'pointer',
                color: '#7144CE',
                fontFamily: 'Baloo 2',
              }}
              onClick={openResetPassword}
            >
              Forgot your password? Reset it.
            </div>
          </div>
        </div>
      )}

      {/* CAPTCHA Modal */}
      {showCaptchaModal && (
        <div>
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000,
            }}
            onClick={closeCaptchaModal} // Allow closing by clicking outside
          ></div>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1001,
              background: '#fff',
              padding: '20px',
              borderRadius: '10px',
              boxShadow: '0 0 15px rgba(0, 0, 0, 0.3)',
              width: '90%',
              maxWidth: '400px',
            }}
          >
            <form onSubmit={handleCaptchaSubmit}>
              <img
                src={captchaSvg}
                alt="Captcha"
                style={{ width: '100%', borderRadius: '10px', marginBottom: '10px' }}
              />
              <input
                style={inputStyle}
                type="text"
                placeholder="Enter CAPTCHA"
                value={captchaResponse}
                onChange={(e) => setCaptchaResponse(e.target.value)}
                required
              />
              <GradientButton type="submit" style={{ width: '100%', marginBottom: '10px' }}>
                Submit CAPTCHA
              </GradientButton>
              <GradientButton type="button" onClick={closeCaptchaModal} style={{ width: '100%' }}>
                Close
              </GradientButton>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Signup;
