import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function App() {
  let navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const storedToken = localStorage.getItem('token');
  const [isTokenValid, setIsTokenValid] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      // Ping the validation endpoint
      fetch(`https://z.ninovium.dev/v1/auth/validate?token=${token}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "Token is valid.") {
            setIsTokenValid(true); // Token is valid, show portal button
          } else {
            setIsTokenValid(false); // Token is invalid, show sign-in/log-in buttons
          }
        })
        .catch(() => {
          setIsTokenValid(false); // On error, show sign-in/log-in buttons
        });
    }
  }, []);

  const headerStyle = {
    background: 'rgba(0, 0, 0, 0.05)',
    backdropFilter: 'blur(40px)',
    WebkitBackdropFilter: 'blur(40px)',
    borderRadius: '0 0 20px 20px',
    borderBottom: '1px solid rgba(79, 71, 167, 0.3)',
    boxShadow: '0 2px 10px rgba(79, 71, 167, 0.1)',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 50px',
    zIndex: 1001,
    position: 'absolute',
    top: 0,
    width: 'calc(100vw - 160px)',
    margin: '0 40px 0 40px',
    fontSize: '18px',
    color: '#4f47a7',
    fontFamily: 'Baloo 2',
  };

  const footerStyle = {
    background: 'rgba(0, 0, 0, 0.05)',
    backdropFilter: 'blur(40px)',
    WebkitBackdropFilter: 'blur(40px)',
    height: '100px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    zIndex: 1001,
    position: 'absolute',
    bottom: 0,
    width: 'calc(100vw - 105px)',
    margin: '0 40px 0 40px',
    borderRadius: '20px 20px 0 0',
    borderTop: '1px solid rgba(79, 71, 167, 0.3)',
    boxShadow: '0 2px 10px rgba(79, 71, 167, 0.1)',
    fontSize: '18px',
    color: '#4f47a7',
    fontFamily: 'Baloo 2',
  };

  const footerButtonContainerStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  };

  const linkStyle = {
    color: '#4f47a7',
    textDecoration: 'none',
    margin: '0 20px',
    fontFamily: 'Baloo 2',
    fontSize: '18px',
    cursor: 'pointer',
    transition: 'color 0.3s ease-in-out',
  };

  const linkHoverStyle = {
    color: '#6a5ecf',
  };

  const backgroundStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
  };

  const appStyle = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    height: '100vh',
    width: '100vw',
    left: '0px',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    background: `url('https://z.ninovium.dev/Untitled.png')`,
    backgroundSize: 'cover',
    fontFamily: 'Baloo 2, sans-serif',
    backdropFilter: 'blur(10px)',
  };

  const hideScrollbar = `
    ::-webkit-scrollbar {
      display: none;
    }
  `;

  const buttonStyle = {
    padding: '15px 30px',
    borderRadius: '30px',
    border: 'none',
    background: 'linear-gradient(90deg, #9c7ef3, #7e56ef)', // Gradient background
    color: 'white',
    fontSize: '18px',
    fontFamily: 'Baloo 2',
    cursor: 'pointer',
    boxShadow: '-1px 3px 1px rgba(0, 0, 0, 0.2)',
    transition: 'background-color 0.3s ease-in-out',
  };
  
  const buttonStyle2 = {
    padding: '15px 30px',
    border: 'none',
    background: 'linear-gradient(90deg, #9c7ef3, #7e56ef)', // Gradient background
    color: 'white',
    fontSize: '18px',
    fontFamily: 'Baloo 2',
    cursor: 'pointer',
    boxShadow: '-1px 3px 1px rgba(0, 0, 0, 0.2)',
    transition: 'background-color 0.3s ease-in-out',
    width: '33%'
  };

  const buttonHoverStyle = {
    backgroundColor: '#4f47a7',
  };

  const heroStyle = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row', // Adjust layout based on mobile view
    alignItems: 'center',
    textAlign: 'center',
    color: '#3f2d7e',
    justifyContent: 'space-between'
  };

  const heroTextContainerStyle = {
    maxWidth: '600px',
    paddingRight: '20px', // Add padding on the right to create space between the text and the image
    textAlign: isMobile ? 'center' : 'left',
    margin: '0 0 0 60px'
  };

  const heroHeadingStyle = {
    fontSize: '48px',
    fontWeight: 'bold',
    lineHeight: '1.2',
    margin: '20px 0',
  };

  const heroSubtextStyle = {
    fontSize: '20px',
    marginBottom: '40px',
  };

  const imageContainerStyle = {
    position: 'relative',
    width: isMobile ? '100%' : '50%',
    marginLeft: '30px',
    marginRight: '60px'
  };

  const execImageStyle = {
    width: '100%',
    borderRadius: '11px',
    border: '3px solid #d0c2ff',
    marginTop: isMobile ? '20px' : '0',
  };

  const overlayStyle = {
    position: 'absolute',
    bottom: '-30px',
    left: '-30px',
    display: 'flex',
    width: '100%',
    maxWidth: '470px',
    height: '100px',
    background: 'rgba(0, 0, 0, 0.05)',
    backdropFilter: 'blur(40px)',
    WebkitBackdropFilter: 'blur(40px)',
    borderRadius: '0 0 20px 20px',
    border: '1px solid rgba(79, 71, 167, 0.3)',
    borderRight: 'none',
    boxShadow: '0 2px 10px rgba(79, 71, 167, 0.1)',
    borderRadius: '10px',
    padding: '10px',
    alignItems: 'center',
  };

  const overlayImageStyle = {
    width: '90px',
    marginRight: '10px',
  };

  const overlayTextStyle = {
    fontSize: '16px',
    color: '#333',
    textAlign: 'left',
  };

  return (
    <div style={appStyle}>
      <style>{hideScrollbar}</style>

      {/* Header */}
      <div style={headerStyle}>
        <div>
          <img src="https://z.ninovium.dev/e.png" alt="Ninovium Logo" style={{ height: '50px', marginTop: '5px' }} />
        </div>
        <div>
          <a
            onMouseEnter={e => e.target.style.color = linkHoverStyle.color}
            onMouseLeave={e => e.target.style.color = linkStyle.color}
            onClick={(e) => { e.preventDefault(); navigate('/'); }}
            style={linkStyle}
          >
            Home
          </a>
          {!isMobile && (
            <a
              onMouseEnter={e => e.target.style.color = linkHoverStyle.color}
              onMouseLeave={e => e.target.style.color = linkStyle.color}
              href="/documentation"
              style={linkStyle}
            >
              Documentation
            </a>
          )}
          <a
            onMouseEnter={e => e.target.style.color = linkHoverStyle.color}
            onMouseLeave={e => e.target.style.color = linkStyle.color}
            href="/support"
            style={linkStyle}
          >
            Support
          </a>
        </div>
        <div>
          {isTokenValid ? (
            <a
              onMouseEnter={e => e.target.style.color = linkHoverStyle.color}
              onMouseLeave={e => e.target.style.color = linkStyle.color}
              onClick={() => navigate('/portal')}
              style={linkStyle}>
              Portal
            </a>
          ) : (
            <>
              <a
                onMouseEnter={e => e.target.style.color = linkHoverStyle.color}
                onMouseLeave={e => e.target.style.color = linkStyle.color}
                onClick={() => navigate('/register')}
                style={linkStyle}>
                Sign In
              </a>
              <a
                onMouseEnter={e => e.target.style.color = linkHoverStyle.color}
                onMouseLeave={e => e.target.style.color = linkStyle.color}
                onClick={() => navigate('/register')}
                style={linkStyle}>
                Sign Up
              </a>
            </>
          )}
        </div>
      </div>

      <div style={heroStyle}>
        <div style={heroTextContainerStyle}>
          <h1 style={heroHeadingStyle}>Breaking boundaries that were never set.</h1>
          <p style={heroSubtextStyle}>
            Ninovium's powerful Lua debugging utility allows you to write and execute code like never before.
          </p>
          <button
            style={buttonStyle}
            onMouseEnter={e => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor}
            onMouseLeave={e => e.target.style.backgroundColor = buttonStyle.backgroundColor}
          >
            Get Started
          </button>
          <button
            style={{ ...buttonStyle, marginLeft: '10px' }}
            onMouseEnter={e => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor}
            onMouseLeave={e => e.target.style.backgroundColor = buttonStyle.backgroundColor}
          >
            Documentation
          </button>
        </div>
        <div style={imageContainerStyle}>
          <img
            src="https://z.ninovium.dev/lightexc.png"
            alt="CRM Dashboard"
            style={execImageStyle}
          />
          <div style={overlayStyle}>
            <img src="https://z.ninovium.dev/oracle.png" alt="Oracle Logo" style={overlayImageStyle} />
            <div style={overlayTextStyle}>
              <h1 style={{marginBottom: '-10px', marginTop: '-10px'}}>Oracle</h1>
              Native support for Oracle Lua Decompiler. Ability to convert Lua/Luau bytecode to valid code.
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div style={footerStyle}>
        <div style={footerButtonContainerStyle}>
          <button
            style={{ ...buttonStyle2, borderRadius: '20px 0 0 20px', fontWeight: '700'}}
            onMouseEnter={e => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor}
            onMouseLeave={e => e.target.style.backgroundColor = buttonStyle.backgroundColor}
            onClick={() => window.open('https://discord.com', '_blank')}
          >
            Join The Community
          </button>
          <button
            style={{ ...buttonStyle2, borderRadius: '0 0 0 0', fontWeight: '700'}}
            onMouseEnter={e => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor}
            onMouseLeave={e => e.target.style.backgroundColor = buttonStyle.backgroundColor}
            onClick={() => navigate('/products')}
          >
            Products
          </button>
          <button
            style={{ ...buttonStyle2, borderRadius: '0px 20px 20px 0', fontWeight: '700'}}
            onMouseEnter={e => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor}
            onMouseLeave={e => e.target.style.backgroundColor = buttonStyle.backgroundColor}
            onClick={() => navigate('/terms')}
          >
            Terms Of Service
          </button>
        </div>
      </div>

      <div style={backgroundStyle}></div>
    </div>
  );
}

export default App;
