// support.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-lua';
import { MdPayment, MdOutlineSupportAgent } from 'react-icons/md';
import { AiOutlineGlobal } from 'react-icons/ai';
import { FaRegQuestionCircle } from 'react-icons/fa';

// SlideIn Component for Alerts and Confirmations
const SlideIn = ({ isOpen, title, message, onClose, onConfirm, showConfirm }) => {
  if (!isOpen) return null;

  return (
    <div style={overlayStyle} onClick={onClose}>
      <div style={slideInStyle} onClick={(e) => e.stopPropagation()}>
        <h2 style={titleStyle}>{title}</h2>
        <p style={messageStyle}>{message}</p>
        <div style={buttonContainerStyle}>
          {showConfirm && (
            <button style={confirmButtonStyle} onClick={onConfirm}>
              Confirm
            </button>
          )}
          <button style={closeButtonStyle} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

// Styles for SlideIn
const overlayStyle = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  zIndex: 1000,
};

const slideInStyle = {
  background: '#fff',
  width: '100%',
  maxWidth: '500px',
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '20px',
  padding: '20px',
  boxShadow: '0 -2px 10px rgba(0,0,0,0.2)',
  animation: 'slideUp 0.3s ease-out forwards',
};

const titleStyle = {
  margin: '0 0 10px 0',
  fontSize: '20px',
  color: '#4f47a7',
};

const messageStyle = {
  fontSize: '16px',
  color: '#555',
};

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
  marginTop: '20px',
};

const confirmButtonStyle = {
  padding: '10px 20px',
  backgroundColor: '#7e56ef',
  color: '#fff',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
};

const closeButtonStyle = {
  padding: '10px 20px',
  backgroundColor: '#ccc',
  color: '#333',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
};

function Support() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [parsedContent, setParsedContent] = useState([]);
  const [loading, setLoading] = useState(false); // Initialize as true since we're loading

  const [isTokenValid, setIsTokenValid] = useState(false); // State to handle token validity

  // SlideIn state variables
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTitle, setAlertTitle] = useState('Notification');

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [confirmTitle, setConfirmTitle] = useState('Confirmation');
  const [confirmAction, setConfirmAction] = useState(null);

  // Animation state variables
  const [animationDirection, setAnimationDirection] = useState('forward');

  // State for managing selected widget
  const [selectedWidget, setSelectedWidget] = useState(null);

  // Detect if the device is mobile (optional)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Token validation useEffect
  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      // Ping the validation endpoint
      fetch(`https://z.ninovium.dev/v1/auth/validate?token=${token}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "Token is valid.") {
            setIsTokenValid(true); // Token is valid, show portal button
          } else {
            setIsTokenValid(false); // Token is invalid, show sign-in/log-in buttons
          }
        })
        .catch(() => {
          setIsTokenValid(false); // On error, show sign-in/log-in buttons
        });
    } else {
      setIsTokenValid(false); // No token found
    }
  }, []);

  const handleConfirm = () => {
    if (confirmAction) confirmAction();
    setIsConfirmOpen(false);
  };

  // Handle navigation with reverse animations
  const handleNavigation = (path) => {
    setAnimationDirection('reverse');

    // Duration should match your animation duration (0.5s in this case)
    setTimeout(() => {
      navigate(path);
    }, 500); // 500ms = 0.5s
  };

  // Content for support widgets
  const supportItems = [
    { id: 1, icon: <FaRegQuestionCircle size={40} />, name: "General Inquiry", description: "Get answers to general questions." },
    { id: 2, icon: <MdOutlineSupportAgent size={40} />, name: "Technical Support", description: "Help with technical issues and bugs." },
    { id: 3, icon: <MdPayment size={40} />, name: "Billing & Account", description: "Questions about billing or account settings." },
    { id: 4, icon: <AiOutlineGlobal size={40} />, name: "Website & Software", description: "Report website or software issues or errors." },
  ];

  // Handle widget click
  const handleWidgetClick = (id) => {
    if (selectedWidget === id) {
      // If the same widget is clicked again, deselect it
      setSelectedWidget(null);
    } else {
      setSelectedWidget(id);
    }
  };

  // Simulate content loading
  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 1 second delay
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div style={{ ...appStyle, paddingTop: '100px' }}>
        <div className="loading-animation">Loading...</div>
        {/* Optionally, add a spinner or animated loader here */}
      </div>
    );
  }

  return (
    <div style={appStyle}>
      {/* Inject CSS Styles */}
      <style>{animationStylesBlock}</style>

      {/* Header */}
      <div className={animationDirection === 'forward' ? 'header-animated' : 'header-reverse-animated'} style={headerStyle}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          {/* Left: Logo and Text */}
          <div style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: '-30px' }}>
            <img
              src="https://z.ninovium.dev/Logo.svg"
              alt="Ninovium Logo"
              style={{ height: '25px', marginTop: '-3px' }}
            />
            <span style={{ marginLeft: '7px', fontSize: '26px', fontWeight: '500', color: '#4f47a7' }}>
              Ninovium
            </span>
          </div>

          {/* Middle: Links (Home, Documentation, Support) */}
          <div
            style={{
              position: 'absolute', // Use absolute positioning
              left: '50%', // Center horizontally
              transform: 'translateX(-50%)', // Offset by 50% of its own width to center properly
              display: 'flex', // Ensure the links are still flexed
              justifyContent: 'center',
            }}
          >
            <a
              onMouseEnter={e => (e.target.style.color = '#6a5ecf')}
              onMouseLeave={e => (e.target.style.color = '#4f47a7')}
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('/');
              }}
              style={linkStyle}
            >
              Home
            </a>
            {!isMobile && (
              <a
                onMouseEnter={e => (e.target.style.color = '#6a5ecf')}
                onMouseLeave={e => (e.target.style.color = '#4f47a7')}
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigation('/documentation');
                }}
                style={linkStyle}
              >
                Documentation
              </a>
            )}
            <a
              onMouseEnter={e => (e.target.style.color = '#6a5ecf')}
              onMouseLeave={e => (e.target.style.color = '#4f47a7')}
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('/support');
              }}
              style={linkStyle}
            >
              Support
            </a>
          </div>

          {/* Right: Sign In/Sign Up or Portal Button based on token validity */}
          <div style={{ flex: '1', display: 'flex', justifyContent: 'flex-end' }}>
            {isTokenValid ? (
              <a
                onClick={() => handleNavigation('/portal')}
                style={{
                  ...linkStyle,
                  ...buttonStyle3,
                  borderRadius: '20px',
                  marginRight: '-40px',
                }}
              >
                Portal
              </a>
            ) : (
              <>
                <a
                  onClick={() => handleNavigation('/register')}
                  style={{
                    ...linkStyle,
                    ...buttonStyle3,
                    borderRadius: '20px 0 0 20px',
                    marginRight: '-20px',
                  }}
                >
                  Sign In
                </a>
                <a
                  onClick={() => handleNavigation('/register')}
                  style={{
                    ...linkStyle,
                    ...buttonStyle3,
                    borderRadius: '0 20px 20px 0',
                    marginRight: '-40px',
                  }}
                >
                  Sign Up
                </a>
              </>
            )}
          </div>
        </div>
      </div>

      <div className={animationDirection === 'forward' ? 'main-content-animated' : 'main-content-reverse-animated'} style={mainContentStyle}>
        {/* Support Widgets Grid */}
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '20px' }}>
          {supportItems.map((item) => (
            <div
              key={item.id}
              onClick={() => handleWidgetClick(item.id)}
              style={{
                padding: '20px',
                background: selectedWidget === item.id ? '#fff' : '#f1f1f1',
                borderRadius: '10px',
                cursor: 'pointer',
                border: selectedWidget === item.id ? '2px solid #7e56ef' : '2px solid transparent',
                opacity: selectedWidget && selectedWidget !== item.id ? 0.5 : 1,
                transition: 'all 0.3s ease',
              }}
            >
              <div style={{ fontSize: '40px', textAlign: 'center' }}>{item.icon}</div>
              <h3 style={{ marginTop: '10px', textAlign: 'center', color: '#4f47a7' }}>{item.name}</h3>
              <p style={{ textAlign: 'center', color: '#555' }}>{item.description}</p>
            </div>
          ))}
        </div>

        {/* Articles Section */}
        <div style={articlesSectionStyle}>
          {selectedWidget === 1 && <ArticleGeneralInquiry onClose={() => setSelectedWidget(null)} />}
          {selectedWidget === 2 && <ArticleTechnicalSupport onClose={() => setSelectedWidget(null)} />}
          {selectedWidget === 3 && <ArticleBillingAccount onClose={() => setSelectedWidget(null)} />}
          {selectedWidget === 4 && <ArticleWebsiteSoftware onClose={() => setSelectedWidget(null)} />}
        </div>
      </div>

      {/* SlideIn for Alerts */}
      <SlideIn
        isOpen={isAlertOpen}
        title={alertTitle}
        message={alertMessage}
        onClose={() => setIsAlertOpen(false)}
        showConfirm={false}
      />

      {/* SlideIn for Confirmations */}
      <SlideIn
        isOpen={isConfirmOpen}
        title={confirmTitle}
        message={confirmMessage}
        onClose={() => setIsConfirmOpen(false)}
        onConfirm={handleConfirm}
        showConfirm={true}
      />
    </div>
  );
}

export default Support;

const ArticleGeneralInquiry = ({ onClose }) => {
  return (
    <div style={{
      padding: '20px',
      background: '#fff',
      borderRadius: '10px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '20px'
    }}>
      {/* Oracle Section */}
      <div style={{
        flex: 1,
        padding: '20px',
        background: 'linear-gradient(to bottom, #bed5e6, #87a4c5)',
        borderRadius: '10px',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
        position: 'relative',
        overflow: 'hidden'
      }}>
        <img
          src="https://z.ninovium.dev/oracle.png"
          alt="Oracle Logo"
          style={{
            height: '30rem',
            marginBottom: '10px',
            position: 'absolute',
            top: '10px',
            right: '10px',
            transform: 'rotate(15deg)',
            opacity: '0.2',
            zIndex: '0'
          }}
        />
        <iframe
          src="https://discord.com/widget?id=1258765614101696533&theme=light"
          width="350"
          height="500"
          allowtransparency="true"
          frameBorder="0"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          style={{ zIndex: '1' }}
        />
      </div>

      {/* Ninovium Section */}
      <div style={{
        flex: 1,
        padding: '20px',
        background: 'linear-gradient(to bottom, #e1def2, #d4cdf1)',
        borderRadius: '10px',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
        position: 'relative',
        overflow: 'hidden'
      }}>
        <img
          src="https://z.ninovium.dev/Logo.svg"
          alt="Ninovium Logo"
          style={{
            height: '25rem',
            marginBottom: '10px',
            position: 'absolute',
            top: '10px',
            right: '10px',
            transform: 'rotate(15deg)',
            opacity: '0.2',
            zIndex: '0'
          }}
        />
        <iframe
          src="https://discord.com/widget?id=1269807225392857189&theme=light"
          width="350"
          height="500"
          allowtransparency="true"
          frameBorder="0"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          style={{ zIndex: '1' }}
        />
      </div>
    </div>
  );
};

const ArticleTechnicalSupport = ({ onClose }) => {
  return (
    <div style={articleStyle}>
      <div style={articleHeaderStyle}>
        <h2>Technical Support</h2>
        <button onClick={onClose} style={closeArticleButtonStyle}>×</button>
      </div>
      <div>
        <p>Coming Soon</p>
      </div>
    </div>
  );
};

const ArticleBillingAccount = ({ onClose }) => {
  return (
    <div style={articleStyle}>
      <div style={articleHeaderStyle}>
        <h2>Billing & Account</h2>
        <button onClick={onClose} style={closeArticleButtonStyle}>×</button>
      </div>
      <div>
        <p>Coming Soon</p>
      </div>
    </div>
  );
};

const ArticleWebsiteSoftware = ({ onClose }) => {
  return (
    <div style={articleStyle}>
      <div style={articleHeaderStyle}>
        <h2>Website & Software</h2>
        <button onClick={onClose} style={closeArticleButtonStyle}>×</button>
      </div>
      <div>
        <p>Coming Soon</p>
      </div>
    </div>
  );
};

// Styles for Articles
const articlesSectionStyle = {
  marginTop: '40px',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
};

const appStyle = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflowY: 'hidden',
  minHeight: '100vh',
  maxWidth: '100vw',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  background: "url('https://z.ninovium.dev/bg-test.jpg')",
  backgroundSize: 'cover',
  fontFamily: 'Baloo 2, sans-serif',
  backdropFilter: 'blur(10px)',
  color: '#4a4a4a',
  overflowX: 'hidden'
};

const headerStyle = {
  background: 'rgba(0, 0, 0, 0.05)',
  backdropFilter: 'blur(40px)',
  WebkitBackdropFilter: 'blur(40px)',
  borderRadius: '0 0 20px 20px',
  borderBottom: '1px solid rgba(79, 71, 167, 0.3)',
  boxShadow: '0 2px 10px rgba(79, 71, 167, 0.1)',
  height: '50px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 50px',
  zIndex: 1001,
  position: 'absolute',
  top: 0,
  width: 'calc(100vw - 160px)',
  margin: '0 40px 0 40px',
  fontSize: '18px',
  color: '#4f47a7',
  fontFamily: 'Baloo 2',
};

const linkStyle = {
  color: '#4f47a7',
  textDecoration: 'none',
  margin: '0 20px',
  fontFamily: 'Baloo 2, sans-serif',
  fontSize: '18px',
  cursor: 'pointer',
  transition: 'color 0.3s ease-in-out',
};

const buttonStyle3 = {
  padding: '5px 30px',
  border: 'none',
  background: 'linear-gradient(90deg, #9c7ef3, #7e56ef)', // Gradient background
  color: 'white',
  fontSize: '18px',
  fontFamily: 'Baloo 2, sans-serif',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease-in-out',
  borderRadius: '20px',
};

const mainContentStyle = {
  flex: 1,
  padding: '30px',
  marginTop: '80px', // To account for fixed header
  marginLeft: '50px',
  marginRight: '50px',
  borderRadius: '20px 20px 0 0',
  background: 'rgba(0, 0, 0, 0.05)',
  backdropFilter: 'blur(40px)',
  WebkitBackdropFilter: 'blur(40px)',
  borderBottom: '1px solid rgba(79, 71, 167, 0.3)',
  boxShadow: '0 2px 10px rgba(79, 71, 167, 0.1)',
  borderTop: '1px solid rgba(79, 71, 167, 0.3)',
  width: 'calc(100vw - 120px)',
  maxHeight: 'calc(100vh - 143px)',
  overflowY: 'auto',
};

// Animation Styles
const animationStylesBlock = `
  /* Hide scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Keyframe Animations */
  @keyframes slideUp {
    from { transform: translateY(100%); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }

  @keyframes slideDown {
    from { transform: translateY(-100%); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }

  @keyframes slideInLeft {
    from { transform: translateX(-100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }

  @keyframes slideInRight {
    from { transform: translateX(100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }

  @keyframes slideOutLeft {
    from { transform: translateY(0); opacity: 1; }
    to { transform: translateY(100%); opacity: 0; }
  }

  @keyframes slideOutRight {
    from { transform: translateX(0); opacity: 1; }
    to { transform: translateX(100%); opacity: 0; }
  }

  @keyframes slideHeaderUp {
    from { transform: translateY(0); opacity: 1; }
    to { transform: translateY(-100%); opacity: 0; }
  }

  /* Animation Classes */
  .header-animated {
    animation: slideDown 0.5s ease-out forwards;
  }

  .header-reverse-animated {
    animation: slideHeaderUp 0.5s ease-out forwards;
  }

  .main-content-animated {
    animation: slideUp 0.5s ease-out forwards;
  }

  .main-content-reverse-animated {
    animation: slideOutLeft 0.5s ease-out forwards;
  }

  /* Loading Animation (Optional) */
  .loading-animation {
    font-size: 24px;
    color: #4f47a7;
    animation: slideUp 1s ease-out forwards;
  }

  /* Article Animations */
  .article-animated {
    animation: slideInRight 0.5s ease-out forwards;
  }

  .article-reverse-animated {
    animation: slideOutLeft 0.5s ease-out forwards;
  }
`;

// Additional Styles for Articles
const articleStyle = {
  padding: '20px',
  background: '#fff',
  borderRadius: '10px',
  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  position: 'relative',
};

const articleHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid #ddd',
  paddingBottom: '10px',
  marginBottom: '10px',
};

const closeArticleButtonStyle = {
  background: 'transparent',
  border: 'none',
  fontSize: '24px',
  cursor: 'pointer',
  color: '#999',
};
