import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Reusable GradientButton Component
const GradientButton = ({ children, onClick, style, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);

  const defaultStyle = {
    padding: '15px 30px',
    borderRadius: '30px',
    border: 'none',
    background: 'linear-gradient(90deg, #9c7ef3, #7e56ef)',
    backgroundSize: '200% 200%',
    backgroundPosition: isHovered ? 'right' : 'left',
    color: 'white',
    fontSize: '18px',
    fontFamily: 'Baloo 2',
    cursor: 'pointer',
    boxShadow: '0 2px 10px rgba(79, 71, 167, 0.4)',
    transition: 'background-position 0.5s ease-in-out, transform 0.3s ease-in-out',
    ...style,
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <button
      style={defaultStyle}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    >
      {children}
    </button>
  );
};

function App() {
  let navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const storedToken = localStorage.getItem('token');
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [animate, setAnimate] = useState(false); // State to trigger forward animations
  const [animationDirection, setAnimationDirection] = useState('forward'); // 'forward' or 'reverse'
  const [navigatingTo, setNavigatingTo] = useState(null); // Target route for navigation

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Trigger forward animations after component mounts
    setAnimate(true);
    setAnimationDirection('forward');
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      // Ping the validation endpoint
      fetch(`https://z.ninovium.dev/v1/auth/validate?token=${token}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "Token is valid.") {
            setIsTokenValid(true); // Token is valid, show portal button
          } else {
            setIsTokenValid(false); // Token is invalid, show sign-in/log-in buttons
          }
        })
        .catch(() => {
          setIsTokenValid(false); // On error, show sign-in/log-in buttons
        });
    }
  }, []);

  // Define keyframe animations in the style block
  const hideScrollbar = 
    `
    ::-webkit-scrollbar {
      display: none;
    }

    /* Slide-in animations */
    @keyframes slideDown {
      from { transform: translateY(-100%); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    }

    @keyframes slideUp {
      from { transform: translateY(100%); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    }

    @keyframes slideInLeft {
      from { transform: translateX(-100%); opacity: 0; }
      to { transform: translateX(0); opacity: 1; }
    }

    @keyframes slideInRight {
      from { transform: translateX(100%); opacity: 0; }
      to { transform: translateX(0); opacity: 1; }
    }

    /* Reverse animations */
    @keyframes slideOutLeft {
      from { transform: translateX(0); opacity: 1; }
      to { transform: translateX(-100%); opacity: 0; }
    }

    @keyframes slideOutRight {
      from { transform: translateX(0); opacity: 1; }
      to { transform: translateX(100%); opacity: 0; }
    }

    @keyframes slideHeaderUp {
      from { transform: translateY(0); opacity: 1; }
      to { transform: translateY(-100%); opacity: 0; }
    }

    @keyframes slideFooterDown {
      from { transform: translateY(0); opacity: 1; }
      to { transform: translateY(100%); opacity: 0; }
    }
    `;

  // Header Styles with Animation
  const headerStyle = {
    background: 'rgba(0, 0, 0, 0.05)',
    backdropFilter: 'blur(40px)',
    WebkitBackdropFilter: 'blur(40px)',
    borderRadius: '0 0 20px 20px',
    borderBottom: '1px solid rgba(79, 71, 167, 0.3)',
    boxShadow: '0 2px 10px rgba(79, 71, 167, 0.1)',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 50px',
    zIndex: 1001,
    position: 'absolute',
    top: 0,
    width: 'calc(100vw - 160px)',
    margin: '0 40px 0 40px',
    fontSize: '18px',
    color: '#4f47a7',
    fontFamily: 'Baloo 2',
    opacity: animationDirection === 'forward' ? (animate ? 1 : 0) : (animationDirection === 'reverse' ? 0 : 1),
    animation: animationDirection === 'forward'
      ? (animate ? 'slideDown 1s ease-out forwards' : 'none')
      : 'slideHeaderUp 1s ease-out forwards',
  };

  // Footer Styles with Animation
  const footerStyle = {
    ...headerStyle, // Reuse header style properties
    position: 'absolute',
    bottom: 0,
    top: 'unset',
    borderRadius: '20px 20px 0 0',
    borderTop: '1px solid rgba(79, 71, 167, 0.3)',
    borderBottom: 'none',
    animation: animationDirection === 'forward'
      ? (animate ? 'slideUp 1s ease-out forwards' : 'none')
      : 'slideFooterDown 1s ease-out forwards',
  };

  // Hero Text Container with Animation
  const heroTextContainerStyle = {
    maxWidth: '600px',
    paddingRight: '20px',
    textAlign: isMobile ? 'center' : 'left',
    margin: '0 0 0 60px',
    opacity: animationDirection === 'forward' ? (animate ? 1 : 0) : 0,
    transform: animationDirection === 'forward' ? (animate ? 'translateX(0)' : 'translateX(-100%)') : 'translateX(-100%)',
    animation: animationDirection === 'forward'
      ? (animate ? 'slideInLeft 1s ease-out forwards' : 'none')
      : 'slideOutLeft 1s ease-out forwards',
  };

  // Image Container with Animation
  const imageContainerStyle = {
    position: 'relative',
    width: isMobile ? '100%' : '50%',
    marginLeft: '30px',
    marginRight: '10%',
    opacity: animationDirection === 'forward' ? (animate ? 1 : 0) : 0,
    transform: animationDirection === 'forward' ? (animate ? 'translateX(0)' : 'translateX(100%)') : 'translateX(100%)',
    animation: animationDirection === 'forward'
      ? (animate ? 'slideInRight 1s ease-out forwards' : 'none')
      : 'slideOutRight 1s ease-out forwards',
  };

  // Updated overlayStyle with linear gradient background
  const overlayStyle = {
    position: 'absolute',
    bottom: '-30px',
    left: '-30px',
    display: 'flex',
    width: '100%',
    maxWidth: '510px',
    height: '100px',
    background: 'linear-gradient(90deg, #d0c8f1, #e1dcf2)', // Updated to linear gradient
    borderRadius: '10px',
    border: '1px solid rgba(79, 71, 167, 0.3)',
    borderRight: 'none',
    boxShadow: '0 2px 10px rgba(79, 71, 167, 0.1)',
    padding: '10px',
    alignItems: 'center',
    transition: 'all 0.3s ease',
    backdropFilter: 'blur(40px)', // Adjusted blur value
    WebkitBackdropFilter: 'blur(40px)',
    zIndex: '99999',
  };

  const linkStyle = {
    color: '#4f47a7',
    textDecoration: 'none',
    margin: '0 20px',
    fontFamily: 'Baloo 2',
    fontSize: '18px',
    cursor: 'pointer',
    transition: 'color 0.3s ease-in-out',
  };

  const backgroundStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
  };

  const appStyle = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    height: '100vh',
    width: '100vw',
    left: '0px',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    background: `url('https://z.ninovium.dev/bg-test.jpg')`,
    backgroundSize: 'cover',
    fontFamily: 'Baloo 2, sans-serif',
    backdropFilter: 'blur(40px)',
    WebkitBackdropFilter: 'blur(40px)',
  };

  const heroStyle = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row', // Adjust layout based on mobile view
    alignItems: 'center',
    textAlign: 'center',
    color: '#3f2d7e',
    justifyContent: 'space-between',
    paddingTop: '60px', // To prevent overlapping with header
    paddingBottom: '60px', // To prevent overlapping with footer
    width: '100%',
    marginLeft: '10%',
  };

  const heroHeadingStyle = {
    fontSize: '48px',
    fontWeight: 'bold',
    lineHeight: '1.2',
    margin: '20px 0',
  };

  const heroSubtextStyle = {
    fontSize: '20px',
    marginBottom: '40px',
  };

  const execImageStyle = {
    height: '55vh',
    /*borderRadius: '11px',
    border: '3px solid #d0c2ff',*/
    marginTop: isMobile ? '20px' : '0',
  };

  const overlayImageStyle = {
    width: '90px',
    marginRight: '10px',
  };

  const overlayTextStyle = {
    fontSize: '16px',
    color: '#333',
    textAlign: 'left',
  };

  // Handle navigation with reverse animations
  const handleNavigation = (path) => {
    setAnimationDirection('reverse');
    setNavigatingTo(path);

    // Duration should match your animation duration (1s)
    setTimeout(() => {
      navigate(path);
    }, 1000); // 1000ms = 1s
  };

  return (
    <div style={appStyle}>
      <style>{hideScrollbar}</style>
      <div style={headerStyle}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          {/* Left: Logo and Text */}
          <div style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: '-30px' }}>
            <img
              src="https://z.ninovium.dev/Logo.svg"
              alt="Ninovium Logo"
              style={{ height: '25px', marginTop: '-3px' }}
            />
            <span style={{ marginLeft: '7px', fontSize: '26px', fontWeight: '500', color: '#4f47a7' }}>
              Ninovium
            </span>
          </div>

          {/* Middle: Links (Home, Documentation, Support) */}
          <div
            style={{
              position: 'absolute', // Use absolute positioning
              left: '50%', // Center horizontally
              transform: 'translateX(-50%)', // Offset by 50% of its own width to center properly
              display: 'flex', // Ensure the links are still flexed
              justifyContent: 'center',
            }}
          >
            <a
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('/');
              }}
              style={linkStyle}
            >
              Home
            </a>
            {!isMobile && (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigation('/documentation');
                }}
                style={linkStyle}
              >
                Documentation
              </a>
            )}
            <a
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('/support');
              }}
              style={linkStyle}
            >
              Support
            </a>
          </div>

          <div style={{ flex: '1', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {isTokenValid ? (
              <GradientButton
                onClick={() => handleNavigation('/portal')}
                style={{ borderRadius: '20px', marginRight: '10px', padding: '9px 30px', marginRight: '-43px' }}
              >
                Portal
              </GradientButton>
            ) : (
              <div style={{ display: 'flex', gap: '7px', marginRight: '-43px' }}>
                <GradientButton
                  onClick={() => handleNavigation('/register')}
                  style={{ borderRadius: '20px', padding: '9px 30px' }}
                >
                  Sign In
                </GradientButton>
                <GradientButton
                  onClick={() => handleNavigation('/register')}
                  style={{ borderRadius: '20px', padding: '5px 30px' }}
                >
                  Sign Up
                </GradientButton>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Hero Section */}
      <div style={heroStyle}>
        <div style={heroTextContainerStyle}>
          <h1 style={heroHeadingStyle}>Breaking boundaries that were never set.</h1>
          <p style={heroSubtextStyle}>
            Ninovium's powerful Lua debugging utility allows you to write and execute code like never before.
          </p>
          <div style={{ display: 'flex', gap: '10px', justifyContent: isMobile ? 'center' : 'flex-start' }}>
            <GradientButton onClick={() => handleNavigation('/register')}>
              Get Started
            </GradientButton>
            <GradientButton style={{ marginLeft: '10px' }} onClick={() => handleNavigation('/documentation')}>
              Documentation
            </GradientButton>
          </div>
        </div>
        <div style={imageContainerStyle}>
          <img
            src="https://z.ninovium.dev/graphic1.png"
            alt="CRM Dashboard"
            style={execImageStyle}
          />
          {/*<div style={overlayStyle}>
            <img src="https://z.ninovium.dev/oracle.png" alt="Oracle Logo" style={overlayImageStyle} />
            <div style={overlayTextStyle}>
              <h1 style={{ marginBottom: '-10px', marginTop: '-10px' }}>Oracle</h1>
              Native support for Oracle Lua Decompiler. Ability to convert Lua/Luau bytecode to functionally identical code.
            </div>
          </div>*/}
        </div>
      </div>

      {/* Footer */}
      <div style={footerStyle}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          {/* Left: Copyright Notice */}
          <div style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: '-30px' }}>
            <span style={{ ...linkStyle, cursor: 'default' }}>
              © 2024 Ninovium
            </span>
          </div>

          {/* Middle: Links (Join The Community, Development Team, Terms Of Service) */}
          <div
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <a
              href="https://discord.gg/ninovium"
              target="_blank"
              rel="noopener noreferrer"
              style={linkStyle}
            >
              Join The Community
            </a>
            {/*<a
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('/devteam');
              }}
              style={linkStyle}
            >
              Development Team
            </a>*/}
            <a
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('/terms');
              }}
              style={linkStyle}
            >
              Terms Of Service
            </a>
          </div>
        </div>
      </div>

      {/* Background */}
      <div style={backgroundStyle}></div>
    </div>
  );
}

export default App;
