// Portal.js
import React, { useEffect, useState, useRef } from 'react';
import { FaCcStripe, FaBitcoin, FaEllipsisV } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// GradientButton Component
const GradientButton = ({ children, onClick, style, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);

  const defaultStyle = {
    padding: '15px 30px',
    borderRadius: '30px',
    border: 'none',
    background: 'linear-gradient(90deg, #9c7ef3, #7e56ef)',
    backgroundSize: '200% 200%',
    backgroundPosition: isHovered ? 'right' : 'left',
    color: 'white',
    fontSize: '18px',
    fontFamily: 'Baloo 2',
    cursor: 'pointer',
    boxShadow: '0 2px 10px rgba(79, 71, 167, 0.4)',
    transition: 'background-position 0.5s ease-in-out, transform 0.3s ease-in-out',
    ...style,
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <button
      style={defaultStyle}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    >
      {children}
    </button>
  );
};

// SlideIn Component for Alerts and Confirmations
const SlideIn = ({ isOpen, title, message, onClose, onConfirm, showConfirm }) => {
  if (!isOpen) return null;

  return (
    <div style={overlayStyle} onClick={onClose}>
      <div style={slideInStyle} onClick={(e) => e.stopPropagation()}>
        <h2 style={titleStyle}>{title}</h2>
        <p style={messageStyle}>{message}</p>
        <div style={buttonContainerStyle}>
          {showConfirm && (
            <button style={confirmButtonStyle} onClick={onConfirm}>
              Confirm
            </button>
          )}
          <button style={closeButtonStyle} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

// Styles for SlideIn
const overlayStyle = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  zIndex: 1000,
};

const slideInStyle = {
  background: '#fff',
  width: '100%',
  maxWidth: '500px',
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '20px',
  padding: '20px',
  boxShadow: '0 -2px 10px rgba(0,0,0,0.2)',
  animation: 'slideUp 0.3s ease-out forwards',
};

const titleStyle = {
  margin: '0 0 10px 0',
  fontSize: '20px',
  color: '#4f47a7',
};

const messageStyle = {
  fontSize: '16px',
  color: '#555',
};

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
  marginTop: '20px',
};

const confirmButtonStyle = {
  padding: '10px 20px',
  backgroundColor: '#7e56ef',
  color: '#fff',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
};

const closeButtonStyle = {
  padding: '10px 20px',
  backgroundColor: '#ccc',
  color: '#333',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
};

// Portal Component
function Portal() {
  const navigate = useNavigate();
  const [portalInfo, setPortalInfo] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [amount, setAmount] = useState(0);
  const [adjustedAmount, setAdjustedAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dueThisMonth, setDueThisMonth] = useState(0);
  const [copyKeyLabel, setCopyKeyLabel] = useState("Copy Key");
  const [isMobile, setIsMobile] = useState(false); // To handle mobile view if needed

  // SlideIn state variables
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTitle, setAlertTitle] = useState('Notification');

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [confirmTitle, setConfirmTitle] = useState('Confirmation');
  const [confirmAction, setConfirmAction] = useState(null);

  // Animation state variables (unchanged)
  const [animate, setAnimate] = useState(false);
  const [animationDirection, setAnimationDirection] = useState('forward');
  const [navigatingTo, setNavigatingTo] = useState(null);

  // Ref for the product card
  const productCardRef = useRef(null);

  // Removed showMenuButton and related useEffect

  // New state variables for menus
  const [menuOpenNinovium, setMenuOpenNinovium] = useState(false);
  const [menuOpenOracle, setMenuOpenOracle] = useState(false);

  // Styles for the menu items
  const menuItemStyle = {
    padding: '10px 20px',
    background: 'none',
    border: 'none',
    textAlign: 'left',
    width: '100%',
    cursor: 'pointer',
    fontSize: '14px',
    color: '#333',
  };

  const calculateStartDate = (endDate) => {
    const date = new Date(endDate);
    date.setMonth(date.getMonth() - 1);
    return date;
  };

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (productCardRef.current) {
        const containerWidth = productCardRef.current.offsetWidth;
        // Approximate minimum width required to display all buttons and content
        const requiredWidth = 600; // Adjust this value as needed
        // Removed showMenuButton logic
      }
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (amount && amount > 0) {
      const adjusted = Math.round(((parseFloat(amount) + 0.30) / 0.971) * 100) / 100;
      setAdjustedAmount(adjusted);
    } else {
      setAdjustedAmount(0);
    }
  }, [amount]);

  const calculateDueThisMonth = (subscriptions) => {
    let total = 0;
    subscriptions.forEach(sub => {
      if (sub.subscribed) {
        total += parseFloat(sub.monthlyCost.replace('$', ''));
      }
    });
    return total;
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setLoading(false);
      // Validate Token
      axios.get(`https://z.ninovium.dev/v1/auth/validate?token=${token}`)
        .then((response) => {
          const data = response.data;
          if (data.message === "Token is valid.") {
            return axios.get(`https://z.ninovium.dev/v1/auth/portal-info?token=${token}`);
          } else {
            handleNavigation('/register');
            throw new Error('Invalid token');
          }
        })
        .then((response) => {
          const data = response.data;
          if (data && data.message === "Token is valid.") {
            setPortalInfo({
              userid: data.userid,
              username: data.username,
              amount: data.amount,
              discord_userid: data.discord_userid, // Include Discord User ID
            });
          } else {
            handleNavigation('/register');
          }
        })
        .catch(() => {
          // Navigation is already handled in the chain
        });

      // Fetch Subscriptions
      axios.get(`https://z.ninovium.dev/v1/subscriptions?token=${token}`)
        .then((response) => {
          const data = response.data;
          if (data.message === "No subscriptions found.") {
            setSubscriptions([]);
          } else if (data && data.subscriptions) {
            const userSubscriptions = [
              {
                name: 'Ninovium Lua Debugger',
                status: data.subscriptions?.ninovium_ld?.subscribed ? 'Active' : 'Inactive',
                startDate: data.subscriptions?.ninovium_ld?.renewal_date
                  ? formatDate(calculateStartDate(data.subscriptions.ninovium_ld.renewal_date))
                  : 'Not Subscribed',
                endDate: data.subscriptions?.ninovium_ld?.renewal_date
                  ? formatDate(data.subscriptions.ninovium_ld.renewal_date)
                  : 'Not Subscribed',
                monthlyCost: '$8.50',
                subscribed: data.subscriptions?.ninovium_ld?.subscribed || false,
              },
              {
                name: 'Oracle Lua Decompiler',
                status: data.subscriptions?.oracle?.subscribed ? 'Active' : 'Inactive',
                startDate: data.subscriptions?.oracle?.renewal_date
                  ? formatDate(calculateStartDate(data.subscriptions.oracle.renewal_date))
                  : 'Not Subscribed',
                endDate: data.subscriptions?.oracle?.renewal_date
                  ? formatDate(data.subscriptions.oracle.renewal_date)
                  : 'Not Subscribed',
                monthlyCost: '$5.00',
                subscribed: data.subscriptions?.oracle?.subscribed || false,
              }
            ];

            setSubscriptions(userSubscriptions);
            setDueThisMonth(calculateDueThisMonth(userSubscriptions));
          }
        })
        .catch((error) => console.error('Error fetching subscriptions:', error))
        .finally(() => setLoading(false));
    } else {
      handleNavigation('/register');
    }
  }, [navigate]);

  // Recalculate due amount when subscriptions change
  useEffect(() => {
    setDueThisMonth(calculateDueThisMonth(subscriptions));
  }, [subscriptions]);

  // SlideIn handling functions
  const showAlert = (title, message) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setIsAlertOpen(true);
  };

  const showConfirm = (title, message, action) => {
    setConfirmTitle(title);
    setConfirmMessage(message);
    setConfirmAction(() => action);
    setIsConfirmOpen(true);
  };

  const handleConfirm = () => {
    if (confirmAction) confirmAction();
    setIsConfirmOpen(false);
  };

  const handleStripePayment = () => {
    const token = localStorage.getItem('token');
    if (!amount || amount <= 0) {
      showAlert('Error', 'Please enter a valid amount to deposit.');
      return;
    }

    axios.post(`https://z.ninovium.dev/create-checkout-session?token=${token}&amount=${amount}`)
      .then((response) => {
        const data = response.data;
        if (data.url) {
          window.location.href = data.url;
        } else {
          showAlert('Error', 'Error creating checkout session. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        showAlert('Error', 'There was an issue processing your payment. Please try again.');
      });
  };

  const handleSubscribeOrUnsubscribe = (service, subscribed) => {
    const token = localStorage.getItem('token');
    const endpoint = subscribed ? 'unsubscribe' : 'purchase';

    if (subscribed) {
      // Simple confirmation without input
      showConfirm(
        'Confirm Unsubscription',
        `Are you sure you want to unsubscribe from ${service === 'ninovium_ld' ? 'Ninovium Lua Debugger' : 'Oracle Lua Decompiler'}?`,
        () => proceedUnsubscribe(service)
      );
      return;
    }

    // If subscribing
    axios.post(`https://z.ninovium.dev/v1/${endpoint}`, {
      token,
      service, // Pass the service being subscribed/unsubscribed ('oracle' or 'ninovium_ld')
    })
      .then((response) => {
        const data = response.data;
        if (data.message) {
          showAlert('Success', data.message);

          // If subscribing and service is 'ninovium_ld', generate or retrieve the key
          if (!subscribed && service === 'ninovium_ld') {
            handleNinoviumKey(token);
          }

          // Reload to update subscriptions
          window.location.reload();
        } else {
          showAlert('Error', data.error || `Error processing ${subscribed ? 'unsubscription' : 'subscription'}.`);
        }
      })
      .catch((error) => {
        console.error(`Error processing ${subscribed ? 'unsubscription' : 'subscription'}:`, error);
        showAlert('Error', `There was an issue with your ${subscribed ? 'unsubscription' : 'subscription'}. Please try again.`);
      });
  };

  const proceedUnsubscribe = (service) => {
    const token = localStorage.getItem('token');
    axios.post(`https://z.ninovium.dev/v1/unsubscribe`, {
      token,
      service,
    })
      .then((response) => {
        const data = response.data;
        if (data.message) {
          showAlert('Success', data.message);
          window.location.reload();
        } else {
          showAlert('Error', data.error || 'Error processing unsubscription.');
        }
      })
      .catch((error) => {
        console.error('Error processing unsubscription:', error);
        showAlert('Error', 'There was an issue with your unsubscription. Please try again.');
      });
  };

  const handleNinoviumKey = (token) => {
    // First, try to retrieve the existing key
    axios.post(`https://z.ninovium.dev/v1/get-key`, {
      token,
      product: 'ninovium_ld', // Specify the product
    })
      .then((response) => {
        const data = response.data;
        if (data && data.key) {
          copyToClipboard(data.key);
        } else {
          // If no key exists, generate a new one
          axios.post(`https://z.ninovium.dev/v1/generate-key`, {
            token,
            product: 'ninovium_ld', // No HWID for first-time key generation
          })
            .then((res) => {
              const newData = res.data;
              if (newData && newData.key) {
                copyToClipboard(newData.key);
              } else {
                showAlert('Error', 'Error generating key. Please try again.');
              }
            })
            .catch((error) => {
              console.error('Error generating key:', error);
              showAlert('Error', 'Error generating key. Please try again.');
            });
        }
      })
      .catch((error) => {
        console.error('Error retrieving key:', error);
        showAlert('Error', 'Error retrieving key. Please try again.');
      });
  };

  const copyToClipboard = (key) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(key)
        .then(() => {
          setCopyKeyLabel('Copied Key');
          setTimeout(() => {
            setCopyKeyLabel('Copy Key');
          }, 2000);
          showAlert('Success', 'Key copied to clipboard!');
        })
        .catch((error) => {
          console.error('Failed to copy key:', error);
          showAlert('Error', 'Failed to copy the key. Please try again.');
        });
    } else {
      // Fallback method for browsers that don't support the clipboard API
      const tempTextArea = document.createElement('textarea');
      tempTextArea.value = key;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      try {
        document.execCommand('copy');
        setCopyKeyLabel('Copied Key');
        setTimeout(() => {
          setCopyKeyLabel('Copy Key');
        }, 2000);
        showAlert('Success', 'Key copied to clipboard!');
      } catch (error) {
        console.error('Fallback: Failed to copy key:', error);
        showAlert('Error', 'Fallback: Failed to copy the key. Please try again.');
      } finally {
        document.body.removeChild(tempTextArea);
      }
    }
  };

  const colorScheme = {
    primaryButton: '#7e56ef', // Matching color from the first component for buttons
    hoverButton: '#4f47a7', // Hover color from the first component
    borderColor: '#e2e8f0', // Light border for cards
    textColor: '#4f47a7', // Matching text color from first component
    lightText: '#888888',
    sectionBg: '#ffffff',
    redButton: '#f87171', // For unsubscribe button
  };

  // Handle navigation with reverse animations
  const handleNavigation = (path) => {
    setAnimationDirection('reverse');
    setNavigatingTo(path);

    // Duration should match your animation duration (0.5s in this case)
    setTimeout(() => {
      navigate(path);
    }, 500); // 500ms = 0.5s
  };

  // Update animations based on animationDirection and animate state
  useEffect(() => {
    if (animationDirection === 'forward') {
      setAnimate(true);
    }
  }, [animationDirection]);

  // Update the class names based on animation state
  const headerClassName = animationDirection === 'forward' ? 'header-animated' : 'header-reverse-animated';
  const mainContentClassName = animationDirection === 'forward' ? 'main-content-animated' : 'main-content-reverse-animated';

  // Function to determine if a service is subscribed
  const isSubscribed = (service) => {
    const subscription = subscriptions.find(sub => sub.name === service === 'Oracle Lua Decompiler' ? 'Oracle Lua Decompiler' : sub.name === service);
    return subscription && subscription.subscribed; // Returns a boolean (true/false)
  };

  // Function to get the button text based on subscription status
  const getButtonText = (service) => {
    const subscription = subscriptions.find(sub => sub.name === (service === 'oracle' ? 'Oracle Lua Decompiler' : 'Ninovium Lua Debugger'));
    return subscription && subscription.subscribed ? 'Unsubscribe' : 'Subscribe';
  };

  // Function to get the subscription status and decide button color
  const getButtonColor = (service) => {
    const subscription = subscriptions.find(sub => sub.name === (service === 'oracle' ? 'Oracle Lua Decompiler' : 'Ninovium Lua Debugger'));
    return subscription && subscription.subscribed ? colorScheme.redButton : colorScheme.primaryButton;
  };

  const handleDownload = () => {
    window.location.href = 'https://z.ninovium.dev/release/ninovium-installer.exe';
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const appStyle = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: '100vh',
    width: '100vw',
    left: '0px',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    backgroundImage: `url('https://z.ninovium.dev/bg-test.jpg')`,
    backgroundSize: 'cover',
    fontFamily: 'Baloo 2, sans-serif',
    backdropFilter: 'blur(10px)',
    color: '#4a4a4a',
  };

  const headerStyle = {
    background: 'rgba(0, 0, 0, 0.05)',
    backdropFilter: 'blur(40px)',
    WebkitBackdropFilter: 'blur(40px)',
    borderRadius: '0 0 20px 20px',
    borderBottom: '1px solid rgba(79, 71, 167, 0.3)',
    boxShadow: '0 2px 10px rgba(79, 71, 167, 0.1)',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 50px',
    position: 'absolute',
    top: 0,
    width: 'calc(100vw - 160px)',
    margin: '0 40px 0 40px',
    fontSize: '18px',
    color: '#4f47a7',
    fontFamily: 'Baloo 2',
  };

  const linkStyle = {
    color: colorScheme.textColor,
    textDecoration: 'none',
    margin: '0 20px',
    fontFamily: 'Baloo 2, sans-serif',
    fontSize: '18px',
    cursor: 'pointer',
    transition: 'color 0.3s ease-in-out',
  };

  const linkHoverStyle = {
    color: colorScheme.hoverButton,
  };

  const buttonStyle3 = {
    padding: '5px 30px',
    border: 'none',
    background: 'linear-gradient(90deg, #9c7ef3, #7e56ef)', // Gradient background
    color: 'white',
    fontSize: '18px',
    fontFamily: 'Baloo 2, sans-serif',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease-in-out',
    borderRadius: '20px',
  };

  const mainContentStyle = {
    flex: 1,
    padding: '30px',
    marginTop: '80px', // To account for fixed header
    marginLeft: '50px',
    marginRight: '50px',
    borderRadius: '20px 20px 0 0',
    background: 'rgba(0, 0, 0, 0.05)',
    backdropFilter: 'blur(40px)',
    WebkitBackdropFilter: 'blur(40px)',
    borderBottom: `1px solid rgba(79, 71, 167, 0.3)`,
    boxShadow: '0 2px 10px rgba(79, 71, 167, 0.1)',
    borderTop: `1px solid rgba(79, 71, 167, 0.3)`,
    width: 'calc(100vw - 120px)',
  };

  const cardStyle = {
    padding: '20px',
    marginBottom: '20px',
    flex: '3',
  };

  const productCatalogStyle = {
    display: 'flex',
    gap: '20px',
    padding: '20px',
    borderTop: `2px solid rgba(79, 71, 167, 0.3)`,
    borderBottom: `2px solid rgba(79, 71, 167, 0.3)`,
    justifyContent: 'space-between',
    flexWrap: 'wrap', // Allow wrapping on smaller screens
  };

  const rightSidebarStyle = {
    padding: '30px 30px 30px 30px',
    color: colorScheme.textColor,
    flex: '1 1 300px',
    display: 'flex',
    flexDirection: 'column',
    borderRight: `2px solid rgba(79, 71, 167, 0.3)`,
  };

  const flexContainer = {
    display: 'flex',
    gap: '20px',
    alignItems: 'stretch',
    flexWrap: 'wrap', // Allow wrapping on smaller screens
  };

  const depositButtonStyle = {
    padding: '10px',
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    fontWeight: '700',
  };

  const stripeButtonStyle = {
    ...depositButtonStyle,
    backgroundColor: colorScheme.primaryButton,
  };

  const bitcoinButtonStyle = {
    ...depositButtonStyle,
    backgroundColor: '#F7931A', // Bitcoin's brand color
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '10px',
  };

  const tableHeaderStyle = {
    textAlign: 'left',
    fontWeight: 'bold',
    padding: '10px',
    borderBottom: `2px solid rgba(79, 71, 167, 0.3)`,
    color: colorScheme.textColor,
  };

  const tableCellStyle = {
    padding: '10px',
    borderBottom: `1px solid rgba(79, 71, 167, 0.3)`,
  };

  const ninoviumProductStyle = {
    flex: '1 1 45%',
    padding: '30px',
    borderRadius: '15px',
    textAlign: 'left', // Align text to the left
    background: 'linear-gradient(135deg, #e9f0ff, #f2e8fc)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    cursor: 'pointer',
    border: `1px solid rgba(79, 71, 167, 0.3)`,
    position: 'relative', // To position the menu button
  };

  const oracleProductStyle = {
    flex: '1 1 45%',
    padding: '30px',
    borderRadius: '15px',
    textAlign: 'left', // Align text to the left
    background: 'linear-gradient(to bottom, #bed5e6, #87a4c5)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    cursor: 'pointer',
    border: `1px solid rgba(79, 71, 167, 0.3)`,
    position: 'relative', // To position the menu button
  };

  // Define keyframe animations in the style block
  const animationStylesBlock = `
    /* Hide scrollbar */
    ::-webkit-scrollbar {
      display: none;
    }

    /* Slide-in animations */
    @keyframes slideUp {
      from { transform: translateY(100%); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    }

    @keyframes slideDown {
      from { transform: translateY(-100%); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    }

    @keyframes slideInLeft {
      from { transform: translateX(-100%); opacity: 0; }
      to { transform: translateX(0); opacity: 1; }
    }

    @keyframes slideInRight {
      from { transform: translateX(100%); opacity: 0; }
      to { transform: translateX(0); opacity: 1; }
    }

    /* Reverse animations */
    @keyframes slideOutLeft {
      from { transform: translateY(0); opacity: 1; }
      to { transform: translateY(100%); opacity: 0; }
    }

    @keyframes slideOutRight {
      from { transform: translateX(0); opacity: 1; }
      to { transform: translateX(100%); opacity: 0; }
    }

    @keyframes slideHeaderUp {
      from { transform: translateY(0); opacity: 1; }
      to { transform: translateY(-100%); opacity: 0; }
    }

    @keyframes slideFooterDown {
      from { transform: translateY(0); opacity: 1; }
      to { transform: translateY(100%); opacity: 0; }
    }

    /* Hover effects */
    .product-card:hover {
      box-shadow: 0 7px 15px rgba(0, 0, 0, 0.2);
    }

    /* Animation Classes */
    .header-animated {
      animation: slideDown 0.5s ease-out forwards;
    }

    .header-reverse-animated {
      animation: slideHeaderUp 0.5s ease-out forwards;
    }

    .main-content-animated {
      animation: slideUp 0.5s ease-out forwards;
    }

    .main-content-reverse-animated {
      animation: slideOutLeft 0.5s ease-out forwards;
    }

    .product-animated {
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
    }

    .slideInLeft {
      animation-name: slideInLeft;
    }

    .slideInRight {
      animation-name: slideInRight;
    }

    .slideOutLeft {
      animation-name: slideOutLeft;
    }

    .slideOutRight {
      animation-name: slideOutRight;
    }
  `;

  return (
    <div style={appStyle}>
      {/* Inject CSS Styles */}
      <style>{animationStylesBlock}</style>

      {/* Header */}
      <div className={headerClassName} style={headerStyle}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          {/* Left: Logo and Text */}
          <div style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: '-30px' }}>
            <img
              src="https://z.ninovium.dev/Logo.svg"
              alt="Ninovium Logo"
              style={{ height: '25px', marginTop: '-3px' }}
            />
            <span style={{ marginLeft: '7px', fontSize: '26px', fontWeight: '500', color: '#4f47a7' }}>
              Ninovium
            </span>
          </div>

          {/* Middle: Links (Home, Documentation, Support) */}
          <div
            style={{
              position: 'absolute', // Use absolute positioning
              left: '50%', // Center horizontally
              transform: 'translateX(-50%)', // Offset by 50% of its own width to center properly
              display: 'flex', // Ensure the links are still flexed
              justifyContent: 'center',
            }}
          >
            <a
              onMouseEnter={e => (e.target.style.color = linkHoverStyle.color)}
              onMouseLeave={e => (e.target.style.color = linkStyle.color)}
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('/');
              }}
              style={linkStyle}
            >
              Home
            </a>
            {!isMobile && (
              <a
                onMouseEnter={e => (e.target.style.color = linkHoverStyle.color)}
                onMouseLeave={e => (e.target.style.color = linkStyle.color)}
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigation('/documentation');
                }}
                style={linkStyle}
              >
                Documentation
              </a>
            )}
            <a
              onMouseEnter={e => (e.target.style.color = linkHoverStyle.color)}
              onMouseLeave={e => (e.target.style.color = linkStyle.color)}
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('/support');
              }}
              style={linkStyle}
            >
              Support
            </a>
          </div>

          {/* Right: Username and Discord Link */}
          <div style={{ flex: '1', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {portalInfo ? (
              <>
                {/*<strong style={{ color: '#5865f2', marginRight: '20px' }}>{portalInfo.username}</strong>*/}
                {portalInfo.discord_userid ? (
                  <span style={{ color: '#4f47a7', ...buttonStyle3, cursor: 'default', marginRight: '-40px' }}>Linked to Discord</span>
                ) : (
                  <GradientButton
                    onClick={() => handleNavigation(`/link/discord?token=${localStorage.getItem('token')}`)}
                    style={{
                      borderRadius: '20px',
                      cursor: 'pointer',
                      marginRight: '-40px',
                      padding: '9px 30px',
                    }}
                  >
                    Link to Discord
                  </GradientButton>
                )}
              </>
            ) : (
              <span>Loading...</span>
            )}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className={mainContentClassName} style={mainContentStyle}>
        <div style={flexContainer}>
          <div style={{ ...rightSidebarStyle, maxWidth: '300px' }}>
            {portalInfo ? (
              <>
                <p style={{ fontSize: '18px', fontWeight: '700', color: '#4f47a7' }}>Balance (USD)</p>
                <p style={{ fontSize: '16px', marginTop: '-15px', marginBottom: '-10px', color: '#4f47a7' }}>
                  ${portalInfo.amount ? Number(portalInfo.amount).toLocaleString() : 0}
                </p>
                <p style={{ fontSize: '18px', fontWeight: '700', color: '#4f47a7' }}>Paid this month</p>
                <p style={{ fontSize: '16px', marginTop: '-15px', color: '#4f47a7' }}>${dueThisMonth.toFixed(2)}</p>
              </>
            ) : (
              <p>Loading account information...</p>
            )}

            <h3 style={{ color: '#4f47a7', marginTop: '-5px' }}>Deposit</h3>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0' }}>
              <input
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                // Update the amount value
                style={{
                  width: 'calc(60% - 20px)', // Adjust width to fit inline with button
                  padding: '10px',
                  borderRadius: '8px 0 0 8px',
                  outline: 'none',
                  height: '20px',
                  border: `1px solid rgba(79, 71, 167, 0.3)`,
                }}
              />
              <GradientButton
                onClick={handleStripePayment}
                style={{
                  background: colorScheme.primaryButton,
                  padding: '10px',
                  borderRadius: '0 8px 8px 0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  height: '40px',
                  fontWeight: '700',
                  cursor: adjustedAmount >= 10 ? 'pointer' : 'not-allowed',
                }}
                disabled={adjustedAmount < 10}
              >
                <FaCcStripe size={20} /> Stripe
              </GradientButton>
            </div>
            <p style={{ fontSize: '14px', color: '#888888', marginTop: '10px' }}>
              After Stripe fees: <strong>${adjustedAmount}</strong>
            </p>
          </div>

          <div style={{ ...cardStyle }}>
            <h2 style={{ color: '#4f47a7' }}>Active Subscriptions</h2>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={tableHeaderStyle}>Name</th>
                  <th style={tableHeaderStyle}>Start Date</th>
                  <th style={tableHeaderStyle}>Renewal Date</th>
                  <th style={tableHeaderStyle}>Monthly Cost</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.map((sub, index) => (
                  <tr key={index}>
                    <td style={tableCellStyle}>{sub.name}</td>
                    <td style={tableCellStyle}>{sub.startDate}</td>
                    <td style={tableCellStyle}>{sub.endDate}</td>
                    <td style={tableCellStyle}>{sub.monthlyCost}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div style={productCatalogStyle}>
          {/* Ninovium Product */}
          <div
            className="product-card slideInLeft"
            style={ninoviumProductStyle}
            ref={productCardRef} // Attach the ref here
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center', // Ensure vertical centering
                flexWrap: 'wrap',
                gap: '10px',
                width: '100%',
              }}
            >
              <img
                src="https://ninovium.dev/ninovium.png"
                alt="Ninovium Icon"
                style={{ width: '60px', height: '60px', marginRight: '20px' }}
              />
              <div style={{ flexGrow: 1, minWidth: '200px' }}>
                <h4 style={{ fontSize: '22px', fontWeight: 'bold', margin: '0' }}>Ninovium Lua Debugger</h4>
                <p style={{ fontSize: '16px', color: '#555', margin: '0', marginTop: '5px' }}>$8.50/month</p>
              </div>

              {/* 3-Dot Menu Button */}
              <div style={{ position: 'relative' }}>
                <button
                  style={{
                    background: 'transparent',
                    border: 'none',
                    fontSize: '24px',
                    cursor: 'pointer',
                    color: '#4f47a7',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => setMenuOpenNinovium(!menuOpenNinovium)}
                >
                  <FaEllipsisV />
                </button>
                {menuOpenNinovium && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '30px',
                      right: '0',
                      backgroundColor: '#fff',
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                      zIndex: 1000,
                      overflow: 'hidden',
                    }}
                  >
                    {isSubscribed('Ninovium Lua Debugger') && (
                      <>
                        <button
                          onClick={() => {
                            handleNinoviumKey(localStorage.getItem('token'));
                            setMenuOpenNinovium(false);
                          }}
                          style={menuItemStyle}
                        >
                          {copyKeyLabel}
                        </button>
                        <button
                          onClick={() => {
                            handleDownload();
                            setMenuOpenNinovium(false);
                          }}
                          style={menuItemStyle}
                        >
                          Download
                        </button>
                      </>
                    )}
                    <button
                      onClick={() => {
                        handleSubscribeOrUnsubscribe('ninovium_ld', isSubscribed('Ninovium Lua Debugger'));
                        setMenuOpenNinovium(false);
                      }}
                      style={menuItemStyle}
                    >
                      {getButtonText('Ninovium Lua Debugger')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Oracle Product */}
          <div className="product-card slideInRight" style={oracleProductStyle}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center', // Ensure vertical centering
                flexWrap: 'wrap',
                gap: '10px',
                width: '100%',
              }}
            >
              <img
                src="https://z.ninovium.dev/oracle.png"
                alt="Oracle Icon"
                style={{ width: '60px', height: '60px', marginRight: '20px' }}
              />
              <div style={{ flexGrow: 1, minWidth: '200px' }}>
                <h4 style={{ fontSize: '22px', fontWeight: 'bold', margin: '0' }}>Oracle Lua Decompiler</h4>
                <p style={{ fontSize: '16px', color: '#555', margin: '0', marginTop: '5px' }}>$5.00/month</p>
              </div>

              {/* 3-Dot Menu Button */}
              <div style={{ position: 'relative' }}>
                <button
                  style={{
                    background: 'transparent',
                    border: 'none',
                    fontSize: '24px',
                    cursor: 'pointer',
                    color: '#4f47a7',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => setMenuOpenOracle(!menuOpenOracle)}
                >
                  <FaEllipsisV />
                </button>
                {menuOpenOracle && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '30px',
                      right: '0',
                      backgroundColor: '#fff',
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                      zIndex: 1000,
                      overflow: 'hidden',
                    }}
                  >
                    {isSubscribed('Oracle Lua Decompiler') && (
                      <>
                        <button
                          onClick={() => {
                            handleDownload(); // Assuming Oracle has a download option
                            setMenuOpenOracle(false);
                          }}
                          style={menuItemStyle}
                        >
                          Download
                        </button>
                      </>
                    )}
                    <button
                      onClick={() => {
                        handleSubscribeOrUnsubscribe('oracle', isSubscribed('Oracle Lua Decompiler'));
                        setMenuOpenOracle(false);
                      }}
                      style={menuItemStyle}
                    >
                      {getButtonText('Oracle Lua Decompiler')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SlideIn for Alerts */}
      <SlideIn
        isOpen={isAlertOpen}
        title={alertTitle}
        message={alertMessage}
        onClose={() => setIsAlertOpen(false)}
        showConfirm={false}
      />

      {/* SlideIn for Confirmations */}
      <SlideIn
        isOpen={isConfirmOpen}
        title={confirmTitle}
        message={confirmMessage}
        onClose={() => setIsConfirmOpen(false)}
        onConfirm={handleConfirm}
        showConfirm={true}
      />
    </div>
  );
}

export default Portal;
