// Documentation.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { marked } from 'marked';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-lua';

// Reusable GradientButton Component
const GradientButton = ({ children, onClick, style, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);

  const defaultStyle = {
    padding: '15px 30px',
    borderRadius: '30px',
    border: 'none',
    background: 'linear-gradient(90deg, #9c7ef3, #7e56ef)',
    backgroundSize: '200% 200%',
    backgroundPosition: isHovered ? 'right' : 'left',
    color: 'white',
    fontSize: '18px',
    fontFamily: 'Baloo 2',
    cursor: 'pointer',
    boxShadow: '0 2px 10px rgba(79, 71, 167, 0.4)',
    transition: 'background-position 0.5s ease-in-out, transform 0.3s ease-in-out',
    ...style,
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <button
      style={defaultStyle}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    >
      {children}
    </button>
  );
};

// SlideIn Component for Alerts and Confirmations
const SlideIn = ({ isOpen, title, message, onClose, onConfirm, showConfirm }) => {
  if (!isOpen) return null;

  return (
    <div style={overlayStyle} onClick={onClose}>
      <div style={slideInStyle} onClick={(e) => e.stopPropagation()}>
        <h2 style={titleStyle}>{title}</h2>
        <p style={messageStyle}>{message}</p>
        <div style={buttonContainerStyle}>
          {showConfirm && (
            <button style={confirmButtonStyle} onClick={onConfirm}>
              Confirm
            </button>
          )}
          <button style={closeButtonStyle} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

// Styles for SlideIn
const overlayStyle = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  zIndex: 1000,
};

const slideInStyle = {
  background: '#fff',
  width: '100%',
  maxWidth: '500px',
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '20px',
  padding: '20px',
  boxShadow: '0 -2px 10px rgba(0,0,0,0.2)',
  animation: 'slideUp 0.3s ease-out forwards',
};

const titleStyle = {
  margin: '0 0 10px 0',
  fontSize: '20px',
  color: '#4f47a7',
};

const messageStyle = {
  fontSize: '16px',
  color: '#555',
};

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
  marginTop: '20px',
};

const confirmButtonStyle = {
  padding: '10px 20px',
  backgroundColor: '#7e56ef',
  color: '#fff',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
};

const closeButtonStyle = {
  padding: '10px 20px',
  backgroundColor: '#ccc',
  color: '#333',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
};

// Documentation Component
function Documentation() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [parsedContent, setParsedContent] = useState([]);
  const [loading, setLoading] = useState(false);

  // Token validation state
  const [isTokenValid, setIsTokenValid] = useState(false);

  // SlideIn state variables
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTitle, setAlertTitle] = useState('Notification');

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [confirmTitle, setConfirmTitle] = useState('Confirmation');
  const [confirmAction, setConfirmAction] = useState(null);

  // Animation state variables
  const [animationDirection, setAnimationDirection] = useState('forward');

  // Search state variable
  const [searchTerm, setSearchTerm] = useState('');

  // Detect if the device is mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Token validation
  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      // Ping the validation endpoint
      fetch(`https://z.ninovium.dev/v1/auth/validate?token=${token}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.message === 'Token is valid.') {
            setIsTokenValid(true); // Token is valid, show portal button
          } else {
            setIsTokenValid(false); // Token is invalid, show sign-in/log-in buttons
          }
        })
        .catch(() => {
          setIsTokenValid(false); // On error, show sign-in/log-in buttons
        });
    }
  }, []);

  // Markdown and Prism Configuration
  useEffect(() => {
    const handleMarkdownFetch = async () => {
      try {
        const response = await fetch('https://z.ninovium.dev/docs.md');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const text = await response.text();
        const parsed = parseMarkdown(text);
        setParsedContent(parsed);
      } catch (error) {
        console.error('Failed to fetch markdown:', error);
        showAlert('Error', 'Failed to load documentation. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    handleMarkdownFetch();
  }, []);

  useEffect(() => {
    Prism.highlightAll(); // Ensure this runs after the component has rendered
  }, [parsedContent]);

  marked.setOptions({
    highlight: function (code, lang) {
      if (Prism.languages[lang]) {
        return Prism.highlight(code, Prism.languages[lang], lang);
      } else {
        return Prism.highlight(code, Prism.languages['lua'], 'lua');
      }
    },
    breaks: true, // Optional: Enables line breaks
  });

  const parseMarkdown = (markdown) => {
    const tokens = marked.lexer(markdown);
    let sections = [];
    let currentSection = null;
    let currentSubsection = null;

    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];

      if (token.type === 'heading') {
        if (token.depth === 1) {
          if (currentSection) sections.push(currentSection);
          currentSection = { title: token.text, subsections: [] };
          currentSubsection = null;
        } else if (token.depth === 2 && currentSection) {
          if (currentSubsection) {
            currentSection.subsections.push(currentSubsection);
          }
          // Generate a unique id by combining section and subsection titles
          const subsectionId = `${currentSection.title}-${token.text}`.replace(/\s+/g, '-').toLowerCase();
          currentSubsection = { title: token.text, id: subsectionId, content: [] };
        }
      } else if (currentSubsection) {
        // Check for code block followed by description
        if (token.type === 'code') {
          const codeBlock = { type: 'code', content: token, description: null };
          // Look ahead for description
          const nextToken = tokens[i + 1];
          if (
            nextToken &&
            nextToken.type === 'paragraph' &&
            nextToken.text.startsWith('Description:')
          ) {
            codeBlock.description = nextToken.text.replace(/^Description:\s*/, '');
            i++; // Skip the description token
          }
          currentSubsection.content.push(codeBlock);
        } else {
          currentSubsection.content.push(token);
        }
      }
    }

    // Push any remaining subsection and section
    if (currentSubsection) {
      currentSection.subsections.push(currentSubsection);
    }

    if (currentSection) {
      sections.push(currentSection);
    }

    return sections;
  };

  // Filtered content based on search term
  const filteredContent = parsedContent
    .map((section) => {
      const filteredSubsections = section.subsections.filter((subsection) =>
        subsection.title.toLowerCase().includes(searchTerm.toLowerCase())
      );

      if (
        section.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        filteredSubsections.length > 0
      ) {
        return {
          ...section,
          subsections: filteredSubsections,
        };
      } else {
        return null;
      }
    })
    .filter((section) => section !== null);

  const renderContent = () => {
    return filteredContent.map((section) => (
      <div key={section.title}>
        <h1 style={{ color: '#4f47a7' }}>{section.title}</h1>
        {section.subsections.map((subsection) => (
          <div key={subsection.id}>
            <h2 id={subsection.id} style={{ color: '#4f47a7' }}>
              {subsection.title}
            </h2>
            <div>
              {subsection.content.map((token, index) => {
                if (token.type === 'code' && token.content) {
                  // Ensure token.content is defined and has necessary properties
                  const language = token.content.lang || 'lua';
                  const codeText = token.content.text || '';

                  if (codeText.length > 0) {
                    const highlightedCode = Prism.highlight(
                      codeText,
                      Prism.languages[language] || Prism.languages['lua'],
                      language
                    );

                    return (
                      <div key={index} style={{ marginBottom: '20px' }}>
                        <pre>
                          <code
                            className={`language-${language}`}
                            dangerouslySetInnerHTML={{ __html: highlightedCode }}
                          />
                        </pre>
                        {token.description && (
                          <p style={codeDescriptionStyle}>{token.description}</p>
                        )}
                      </div>
                    );
                  } else {
                    return null;
                  }
                } else {
                  // For other token types, use marked to parse them
                  return (
                    <div
                      key={index}
                      dangerouslySetInnerHTML={{ __html: marked.parser([token]) }}
                    />
                  );
                }
              })}
            </div>
          </div>
        ))}
      </div>
    ));
  };

  // SlideIn handling functions
  const showAlert = (title, message) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setIsAlertOpen(true);
  };

  const showConfirm = (title, message, action) => {
    setConfirmTitle(title);
    setConfirmMessage(message);
    setConfirmAction(() => action);
    setIsConfirmOpen(true);
  };

  const handleConfirm = () => {
    if (confirmAction) confirmAction();
    setIsConfirmOpen(false);
  };

  // Handle navigation with reverse animations
  const handleNavigation = (path) => {
    setAnimationDirection('reverse');

    // Duration should match your animation duration (0.5s in this case)
    setTimeout(() => {
      navigate(path);
    }, 500); // 500ms = 0.5s
  };

  // CSS Animations
  const animationStylesBlock = `
    /* Hide scrollbar */
    ::-webkit-scrollbar {
      display: none;
    }

    /* Keyframe Animations */
    @keyframes slideUp {
      from { transform: translateY(100%); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    }

    @keyframes slideDown {
      from { transform: translateY(-100%); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    }

    @keyframes slideInLeft {
      from { transform: translateX(-100%); opacity: 0; }
      to { transform: translateX(0); opacity: 1; }
    }

    @keyframes slideInRight {
      from { transform: translateX(100%); opacity: 0; }
      to { transform: translateX(0); opacity: 1; }
    }

    @keyframes slideOutLeft {
      from { transform: translateY(0); opacity: 1; }
      to { transform: translateY(100%); opacity: 0; }
    }

    @keyframes slideOutRight {
      from { transform: translateX(0); opacity: 1; }
      to { transform: translateX(100%); opacity: 0; }
    }

    @keyframes slideHeaderUp {
      from { transform: translateY(0); opacity: 1; }
      to { transform: translateY(-100%); opacity: 0; }
    }

    /* Animation Classes */
    .header-animated {
      animation: slideDown 0.5s ease-out forwards;
    }

    .header-reverse-animated {
      animation: slideHeaderUp 0.5s ease-out forwards;
    }

    .main-content-animated {
      animation: slideUp 0.5s ease-out forwards;
    }

    .main-content-reverse-animated {
      animation: slideOutLeft 0.5s ease-out forwards;
    }

    /* Loading Animation (Optional) */
    .loading-animation {
      font-size: 24px;
      color: #4f47a7;
      animation: slideUp 1s ease-out forwards;
    }
  `;

  if (loading) {
    return (
      <div style={{ ...appStyle, paddingTop: '100px' }}>
        <div className="loading-animation">Loading...</div>
        {/* Optionally, add a spinner or animated loader here */}
      </div>
    );
  }

  return (
    <div style={appStyle}>
      {/* Inject CSS Styles */}
      <style>{animationStylesBlock}</style>

      {/* Header */}
      <div
        className={
          animationDirection === 'forward' ? 'header-animated' : 'header-reverse-animated'
        }
        style={headerStyle}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {/* Left: Logo and Text */}
          <div
            style={{
              flex: '1',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginLeft: '-30px',
            }}
          >
            <img
              src="https://z.ninovium.dev/Logo.svg"
              alt="Ninovium Logo"
              style={{ height: '25px', marginTop: '-3px' }}
            />
            <span
              style={{
                marginLeft: '7px',
                fontSize: '26px',
                fontWeight: '500',
                color: '#4f47a7',
              }}
            >
              Ninovium
            </span>
          </div>

          {/* Middle: Links (Home, Documentation, Support) */}
          <div
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <a
              onMouseEnter={(e) => (e.target.style.color = '#6a5ecf')}
              onMouseLeave={(e) => (e.target.style.color = '#4f47a7')}
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('/');
              }}
              style={linkStyle}
            >
              Home
            </a>
            {!isMobile && (
              <a
                onMouseEnter={(e) => (e.target.style.color = '#6a5ecf')}
                onMouseLeave={(e) => (e.target.style.color = '#4f47a7')}
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigation('/documentation');
                }}
                style={linkStyle}
              >
                Documentation
              </a>
            )}
            <a
              onMouseEnter={(e) => (e.target.style.color = '#6a5ecf')}
              onMouseLeave={(e) => (e.target.style.color = '#4f47a7')}
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('/support');
              }}
              style={linkStyle}
            >
              Support
            </a>
          </div>

          {/* Right: Sign In/Sign Up or Portal */}
          <div
            style={{
              flex: '1',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {isTokenValid ? (
              <GradientButton
                onClick={() => handleNavigation('/portal')}
                style={{
                  borderRadius: '20px',
                  marginRight: '10px',
                  padding: '9px 30px',
                  marginRight: '-43px',
                }}
              >
                Portal
              </GradientButton>
            ) : (
              <div style={{ display: 'flex', gap: '7px', marginRight: '-43px' }}>
                <GradientButton
                  onClick={() => handleNavigation('/register')}
                  style={{ borderRadius: '20px', padding: '9px 30px' }}
                >
                  Sign In
                </GradientButton>
                <GradientButton
                  onClick={() => handleNavigation('/register')}
                  style={{ borderRadius: '20px', padding: '5px 30px' }}
                >
                  Sign Up
                </GradientButton>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div
        className={
          animationDirection === 'forward'
            ? 'main-content-animated'
            : 'main-content-reverse-animated'
        }
        style={mainContentStyle}
      >
        <div style={flexContainer}>
          {/* Sidebar */}
          <div style={sidebarStyle}>
            <p
              style={{
                fontSize: '2rem',
                marginTop: '0rem',
                color: '#4f47a7',
                fontWeight: '500',
                marginBottom: '0px',
              }}
            >
              Documentation
            </p>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={searchBarStyle}
            />
            {filteredContent.map((section) => (
              <div key={section.title}>
                <a
                  href={`#${section.title.replace(/\s+/g, '-').toLowerCase()}`}
                  style={sidebarItemStyle}
                  onMouseEnter={(e) => (e.target.style.color = '#a78bfa')}
                  onMouseLeave={(e) => (e.target.style.color = '#4f47a7')}
                >
                  {section.title}
                </a>
                {section.subsections.map((subsection) => (
                  <a
                    key={subsection.id}
                    href={`#${subsection.id}`}
                    style={sidebarSubItemStyle}
                    onMouseEnter={(e) => (e.target.style.color = '#a78bfa')}
                    onMouseLeave={(e) => (e.target.style.color = '#4f47a7')}
                  >
                    {subsection.title}
                  </a>
                ))}
              </div>
            ))}
          </div>

          {/* Documentation Content */}
          <div style={scrollableContentStyle}>{renderContent()}</div>
        </div>
      </div>

      {/* SlideIn for Alerts */}
      <SlideIn
        isOpen={isAlertOpen}
        title={alertTitle}
        message={alertMessage}
        onClose={() => setIsAlertOpen(false)}
        showConfirm={false}
      />

      {/* SlideIn for Confirmations */}
      <SlideIn
        isOpen={isConfirmOpen}
        title={confirmTitle}
        message={confirmMessage}
        onClose={() => setIsConfirmOpen(false)}
        onConfirm={handleConfirm}
        showConfirm={true}
      />
    </div>
  );
}

export default Documentation;

// Styles
const scrollableContentStyle = {
  flexGrow: 1,
  maxHeight: 'calc(100vh - 180px)', // Adjust this based on the height of your header and padding
  overflowY: 'auto',
  background: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '20px 0 0 20px',
  padding: '20px 40px',
  color: '#2a2a2a', // Changed to dark gray
  fontFamily: 'Baloo 2',
  zIndex: 2,
  border: '1px solid rgba(79, 71, 167, 0.3)',
  borderRight: 'none',
  background: 'rgba(0, 0, 0, 0.0)',
  boxShadow: '0 2px 10px rgba(79, 71, 167, 0.1)',
  marginRight: '-30px',
};

const appStyle = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflowY: 'hidden',
  minHeight: '100vh',
  width: '100vw',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  background: "url('https://z.ninovium.dev/bg-test.jpg')",
  backgroundSize: 'cover',
  fontFamily: 'Baloo 2, sans-serif',
  color: '#4a4a4a',
};

const headerStyle = {
  background: 'rgba(0, 0, 0, 0.05)',
  backdropFilter: 'blur(40px)',
  WebkitBackdropFilter: 'blur(40px)',
  borderRadius: '0 0 20px 20px',
  borderBottom: '1px solid rgba(79, 71, 167, 0.3)',
  boxShadow: '0 2px 10px rgba(79, 71, 167, 0.1)',
  height: '50px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 50px',
  zIndex: 1001,
  position: 'absolute',
  top: 0,
  width: 'calc(100vw - 160px)',
  margin: '0 40px 0 40px',
  fontSize: '18px',
  color: '#4f47a7',
  fontFamily: 'Baloo 2',
};

const linkStyle = {
  color: '#4f47a7',
  textDecoration: 'none',
  margin: '0 20px',
  fontFamily: 'Baloo 2, sans-serif',
  fontSize: '18px',
  cursor: 'pointer',
  transition: 'color 0.3s ease-in-out',
};

const mainContentStyle = {
  flex: 1,
  padding: '30px',
  marginTop: '80px', // To account for fixed header
  marginLeft: '50px',
  marginRight: '50px',
  borderRadius: '20px 20px 0 0',
  background: 'rgba(0, 0, 0, 0.05)',
  backdropFilter: 'blur(40px)',
  WebkitBackdropFilter: 'blur(40px)',
  borderBottom: '1px solid rgba(79, 71, 167, 0.3)',
  boxShadow: '0 2px 10px rgba(79, 71, 167, 0.1)',
  borderTop: '1px solid rgba(79, 71, 167, 0.3)',
  width: 'calc(100vw - 120px)',
  maxHeight: 'calc(100vh - 143px)',
};

const flexContainer = {
  display: 'flex',
  gap: '20px',
  alignItems: 'stretch',
};

const sidebarStyle = {
  width: '250px',
  height: 'calc(100vh - 180px)',
  borderRadius: '0 20px 20px 0',
  marginRight: '20px',
  padding: '20px 20px 20px 20px', // Updated padding to remove offset
  marginLeft: '-30px',
  overflowY: 'auto',
  background: 'rgba(255, 255, 255, 0.1)',
  border: '1px solid rgba(79, 71, 167, 0.3)',
  background: 'rgba(0, 0, 0, 0.0)',
  borderLeft: 'none',
  boxShadow: '0 2px 10px rgba(79, 71, 167, 0.1)',
};

const sidebarItemStyle = {
  color: '#4f47a7',
  marginBottom: '5px',
  cursor: 'pointer',
  transition: 'color 0.3s ease-in-out',
  textDecoration: 'none',
  display: 'block', // Ensure items stack vertically
  // Removed paddingLeft for main titles
};

const sidebarSubItemStyle = {
  ...sidebarItemStyle,
  paddingLeft: '20px', // Indentation for subsections
  marginBottom: '5px',
};

const codeDescriptionStyle = {
  fontStyle: 'italic',
  color: '#888888',
  marginTop: '5px',
  marginLeft: '10px',
};

const searchBarStyle = {
  width: 'calc(100% - 20px)',
  padding: '10px',
  marginBottom: '10px',
  borderRadius: '10px',
  border: '1px solid rgba(79, 71, 167, 0.3)',
  background: 'rgba(255, 255, 255, 0.7)',
  fontSize: '16px',
  fontFamily: 'Baloo 2, sans-serif',
  outline: 'none',
  color: '#4f47a7',
};
