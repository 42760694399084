import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Argon2, Argon2Mode } from '@sphereon/isomorphic-argon2';

function Signup() {
  let navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(() => JSON.parse(localStorage.getItem('darkMode')) || false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState('Sign Up');
  const [captchaResponse, setCaptchaResponse] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [captchaSvg, setCaptchaSvg] = useState('');
  const [captchaUrl, setCaptchaUrl] = useState('https://z.ninovium.dev/captcha');
  const [isMobile, setIsMobile] = useState(false);
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);
  const [isLogin, setIsLogin] = useState(false); // State to toggle between Sign Up and Log In

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check on initial load

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
    fetchCaptcha();
  }, [darkMode]);

  const fetchCaptcha = async () => {
    try {
      const response = await axios.get(captchaUrl);
      setCaptchaSvg(`data:image/svg+xml;base64,${btoa(response.data.captchaSvg)}`); // Set the CAPTCHA SVG as a data URL
      setCaptchaToken(response.data.captchaToken); // Save the CAPTCHA token
    } catch (err) {
      console.error("Failed to load CAPTCHA:", err);
    }
  };

  const handleAction = async () => {
    setButtonDisabled(true);
    setButtonText('Processing...');

    try {
      const hashResult = await Argon2.hash(password, email, {
        hashLength: 32,
        memory: 1024,
        parallelism: 1,
        mode: Argon2Mode.Argon2id,
        iterations: 1,
      });

      if (isLogin) {
        // Login flow
        const { data } = await axios.post('https://z.ninovium.dev/login', {
          email,
          passwordhash: hashResult.hex,
          captcha: captchaResponse,
          captchaToken,
        });

        if (data.token) {
          localStorage.setItem('token', data.token);
          navigate('/');
        } else {
          setButtonText('Login failed, please try again.');
          setButtonDisabled(false);
        }
      } else {
        // Signup flow
        const { data } = await axios.post('https://z.ninovium.dev/register', {
          username,
          email,
          passwordhash: hashResult.hex,
          captcha: captchaResponse,
          captchaToken,
        });

        if (data.message.includes('User registered successfully')) {
          setButtonText('Awaiting Email Verification');
          checkEmailVerification(hashResult.hex);
        } else {
          setButtonText(data.message);
          setButtonDisabled(false);
        }
      }
    } catch (error) {
      console.error('Error during action:', error);
      const errorMessage = error.response?.data?.message || `${isLogin ? 'Login' : 'Registration'} failed.`;
      setButtonText(errorMessage);
      setButtonDisabled(false);
    }
  };

  const checkEmailVerification = async (passwordHash) => {
    const interval = setInterval(async () => {
      try {
        const { data } = await axios.post('https://z.ninovium.dev/login', {
          email,
          passwordhash: passwordHash,
          captcha: captchaResponse,
          captchaToken,
        });

        if (data.token) {
          clearInterval(interval);
          localStorage.setItem('token', data.token);
          navigate('/');
        }
      } catch (error) {
        if (error.response?.status === 403) {
          setButtonText('Awaiting Email Verification...');
        } else {
          clearInterval(interval);
          setButtonText('Login failed, please verify your email and try again.');
          setButtonDisabled(false);
        }
      }
    }, 2000);
  };

  const openCaptchaModal = () => {
    setShowCaptchaModal(true);
    fetchCaptcha();
  };

  const closeCaptchaModal = () => {
    setShowCaptchaModal(false);
  };

  const handleCaptchaSubmit = () => {
    setShowCaptchaModal(false);
    handleAction();
  };

  const toggleLoginSignup = () => {
    setIsLogin(!isLogin);
    setButtonText(isLogin ? 'Sign Up' : 'Log In');
  };

  const buttonStyle = {
    padding: '15px 30px',
    borderRadius: '30px',
    border: 'none',
    background: buttonDisabled ? '#ccc' : 'linear-gradient(90deg, #9c7ef3, #7e56ef)', // Gradient background
    color: 'white',
    fontSize: '18px',
    fontFamily: 'Baloo 2',
    cursor: 'pointer',
    boxShadow: '-1px 3px 1px rgba(0, 0, 0, 0.2)',
    transition: 'background-color 0.3s ease-in-out',
    width: '100%',
  };

  const formStyle = {
    width: '100%',
    maxWidth: '500px',
    background: 'rgba(0, 0, 0, 0.05)',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '20px',
    border: '1px solid rgba(79, 71, 167, 0.3)',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
  };

  const inputStyle = {
    width: 'calc(100% - 20px)',
    padding: '10px',
    borderRadius: '10px',
    border: '1px solid #ccc',
    fontSize: '16px',
    outline: 'none',
    marginBottom: '10px',
  };

  const sliderContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    position: 'relative',
    width: '100%',
    maxWidth: '500px',
    borderRadius: '30px',
  };

  const sliderButtonStyle = {
    flex: 1,
    padding: '10px',
    textAlign: 'center',
    cursor: 'pointer',
    color: '#3f2d7e',
    fontFamily: 'Baloo 2',
    fontSize: '25px',
    zIndex: 2,
  };

  const sliderIndicatorStyle = {
    position: 'absolute',
    bottom: '0px',
    left: isLogin ? '50%' : '0%',
    width: '40px',
    marginLeft: 'calc(25% - 20px)',
    height: '4px',
    backgroundColor: '#3f2d7e',
    transition: 'left 0.3s ease',
    borderRadius: '30px 30px 0 0',
  };

  return (
    <div style={{ position: 'relative', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundImage: 'url("https://z.ninovium.dev/Untitled.png")', backgroundSize: 'cover', }}>
      
      {/* Form */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
        <div style={formStyle}>
          <div style={sliderContainerStyle}>
            <div style={sliderButtonStyle} onClick={toggleLoginSignup}>Sign Up</div>
            <div style={sliderButtonStyle} onClick={toggleLoginSignup}>Log In</div>
            <div style={sliderIndicatorStyle}></div>
          </div>
          <form onSubmit={(e) => { e.preventDefault(); openCaptchaModal(); }}>
            {!isLogin && (
              <input
                style={inputStyle}
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            )}
            <input
              style={inputStyle}
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              style={inputStyle}
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit" style={buttonStyle} disabled={buttonDisabled}>
              {buttonText}
            </button>
          </form>
        </div>
      </div>

      {showCaptchaModal && (
        <div>
          <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)' }}></div>
          <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1003,
            background: '#fff',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 0 15px rgba(0, 0, 0, 0.3)',
          }}>
            <img src={captchaSvg} alt="Captcha" style={{ width: '100%', borderRadius: '10px', marginBottom: '10px' }} />
            <input
              style={inputStyle}
              type="text"
              placeholder="Enter CAPTCHA"
              value={captchaResponse}
              onChange={(e) => setCaptchaResponse(e.target.value)}
              required
            />
            <button onClick={handleCaptchaSubmit} style={{ ...buttonStyle, marginBottom: '10px '}}>Submit CAPTCHA</button>
            <button onClick={closeCaptchaModal} style={buttonStyle}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Signup;
